import React, { useEffect, useState } from 'react'
import Header from '../component/Header'
import { ABCoin, ERC201, ETHEREUM, GPTPLUS, NA, OXED9BEB, USDBUSD, argent, atomicwallet, bitcoin15, coinbase, exdius, maprock, metamask, tokendistribution } from '../services/image'
import TopHeading from '../component/TopHeading'
import GradientText from '../component/GradientText'
import Footer from '../component/Footer'
import { WOW } from 'wowjs'
import 'animate.css';
import { useLocation } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import TokenomicsBanner from '../component/TokenomicsBanner';
import logEvent from '../analyticsUtils';
import { callPreSaleApi } from '../component/GetSection';

function Tokenomics() {
    const [copied, setCopied] = useState(false);
    const { pathname } = useLocation();
    const location = useLocation();

    useEffect(() => {
        callPreSaleApi();
        logEvent('Tokenomics_page_view', {
            page_path: location.pathname,
            content_type: 'page',
            content_event: 'view'
        });
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    useEffect(() => {
        const wow = new WOW(
            {
                boxClass: 'wow',      // default
                animateClass: 'animated', // default
                offset: 0,          // default
                mobile: true,       // default
                live: false        // default
            }
        )
        wow.init();
    }, [])

    const copyToClipboard = (text) => {
        logEvent('SmartContract_Address_Copy_Btn_Clicked', { content_type: 'button', button: 'SmartContract Copy Btn', content_event: 'click' });
        navigator.clipboard.writeText(text)
            .then(() => {
                setCopied(true);
                setTimeout(() => {
                    setCopied(false);
                }, 3000); // 3 seconds
            })
            .catch((error) => console.log(error));
    };

    return (
        <div className='tokenomics_wrapp'>
            <div className=" bg-size bg-no-repeat bg-center ">
                <div className='lg:mx-[59px] mx-4 '>
                    <Header />
                    <TokenomicsBanner />
                    <div className="lg:py-56 py-10 sm:py-20 md:py-32">
                        <div className="lg:w-3/5 md:w-11/12 w-full mx-auto flex flex-col justify-center items-center">
                            <p className='text-grad7 text-trans md:text-3xl text-2xl lg:text-[46px]/[69px] pr text-center wow animate__animated animate__fadeInUp'>Tokenomics</p>
                            <div className="mt-4 justify-center flex flex-row items-center gap-10 wow animate__animated animate__fadeInUp">
                                <p className='text-[#A9C5E4] text-base/6 text-center '>Send and receive crypto with a simple QR code or wallet address, ensuring hassle-free transfers</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='lg:w-11/12  mx-4 lg:mx-auto lg:-mt-40'>
                <div className="flex flex-wrap gap-8  content-center justify-center">
                    <div className=" h-[315px] pt-8 lg:w-[23%] md:w-[32%] sm:w-[45%] w-full token_card bg-center  bg-no-repeat wow animate__animated animate__fadeInUp">
                        <div className='lg:w-[150px] md:w-[120px] md:h-[120px] w-[100px] h-[100px] lg:h-[150px] mx-auto '>
                            <img src={ABCoin} className='w-full h-full object-contain' alt="" />
                        </div>
                        <h3 className='text-grad9 mt-4 text-trans text-[30px]/[45px] text-center pr'>AB</h3>
                        <p className='text-lg text-white text-center pr'>Symbol</p>
                    </div>
                    <div className=" h-[315px] pt-8 lg:w-[23%] md:w-[32%] sm:w-[45%] w-full token_card bg-center  bg-no-repeat wow animate__animated animate__fadeInUp">
                        <div className='lg:w-[150px] md:w-[120px] md:h-[120px] w-[100px] h-[100px] lg:h-[150px] mx-auto '>
                            <a target='blank' href='https://etherscan.io/token/0x84fAD63F8f26335f4F1BebC9fbF5ba277Fd23C9E#code'>
                                <img src={OXED9BEB} className='w-full h-full object-contain' alt="" />
                            </a>
                        </div>
                        <div className='flex justify-center items-center mt-4'>
                            <a target='blank' href='https://etherscan.io/token/0x84fAD63F8f26335f4F1BebC9fbF5ba277Fd23C9E#code'> <h3 className='text-grad9 text-trans text-[30px]/[45px] text-center pr'>0x84...3C9</h3></a>
                            <div className='flex flex-col ml-4'>
                                <button onClick={() => copyToClipboard('0x84fAD63F8f26335f4F1BebC9fbF5ba277Fd23C9E')} className="text-[#D6AC8F] ml-2 focus:outline-none text-[20px]">
                                    <FontAwesomeIcon icon={faCopy} />
                                </button>
                                {copied && <span className="text-white ml-2 text-xs">Copied!</span>}
                            </div>
                        </div>
                        <a target='blank' href='https://etherscan.io/token/0x84fAD63F8f26335f4F1BebC9fbF5ba277Fd23C9E#code'> <p className='text-lg text-white text-center pr'>Smart Contract</p></a>
                    </div>
                    <div className=" h-[315px] pt-8 lg:w-[23%] md:w-[32%] sm:w-[45%] w-full token_card bg-center  bg-no-repeat wow animate__animated animate__fadeInUp">
                        <div className='lg:w-[150px] md:w-[120px] md:h-[120px] w-[100px] h-[100px] lg:h-[150px] mx-auto '>
                            <img src={ETHEREUM} className='w-full h-full object-contain flipped-horizontal' alt="" />
                        </div>
                        <h3 className='text-grad9 mt-4 text-trans text-[30px]/[45px] text-center pr'>Ethereum</h3>
                        <p className='text-lg text-white text-center pr'>Blockchain</p>
                    </div>
                    <div className=" h-[315px] pt-8 lg:w-[23%] md:w-[32%] sm:w-[45%] w-full token_card bg-center  bg-no-repeat wow animate__animated animate__fadeInUp">
                        <div className='lg:w-[150px] md:w-[120px] md:h-[120px] w-[100px] h-[100px] lg:h-[150px] mx-auto '>
                            <img src={ERC201} className='w-full h-full object-contain' alt="" />
                        </div>
                        <h3 className='text-grad9 mt-4 text-trans text-[30px]/[45px] text-center pr'>ERC-20</h3>
                        <p className='text-lg text-white text-center pr'>Token Type</p>
                    </div>
                    <div className=" h-[315px] pt-8 lg:w-[23%] md:w-[32%] sm:w-[45%] w-full token_card bg-center  bg-no-repeat wow animate__animated animate__fadeInUp">
                        <div className='lg:w-[150px] md:w-[120px] md:h-[120px] w-[100px] h-[100px] lg:h-[150px] mx-auto '>
                            <img src={bitcoin15} className='w-full h-full object-contain' alt="" />
                        </div>
                        <h3 className='text-grad9 mt-4 text-trans text-[30px]/[45px] text-center pr'>500,000,000</h3>
                        <p className='text-lg text-white text-center pr'>Total Supply</p>
                    </div>
                    <div className=" h-[315px] pt-8 lg:w-[23%] md:w-[32%] sm:w-[45%] w-full token_card bg-center  bg-no-repeat wow animate__animated animate__fadeInUp">
                        <div className='lg:w-[150px] md:w-[120px] md:h-[120px] w-[100px] h-[100px] lg:h-[150px] mx-auto '>
                            <img src={NA} className='w-full h-full object-contain' alt="" />
                        </div>
                        <h3 className='text-grad9 mt-4 text-trans text-[30px]/[45px] text-center pr'>0%</h3>
                        <p className='text-lg text-white text-center pr'>Crculating Supply</p>
                    </div>
                    <div className=" h-[315px] pt-8 lg:w-[23%] md:w-[32%] sm:w-[45%] w-full token_card bg-center  bg-no-repeat wow animate__animated animate__fadeInUp">
                        <div className='lg:w-[150px] md:w-[120px] md:h-[120px] w-[100px] h-[100px] lg:h-[150px] mx-auto '>
                            <img src={ETHEREUM} className='w-full h-full object-contain' alt="" />
                        </div>
                        <h3 className='text-grad9 mt-4 text-trans text-[30px]/[45px] text-center pr'>Ethereum</h3>
                        <p className='text-lg text-white text-center pr'>Purchase Methods</p>
                    </div>
                </div>
            </div>
            <div>
                {/* <div className="lg:pt-[134px] md:pt-24 sm:pt-14 pt-10 wow animate__animated animate__fadeInUp">
                    <TopHeading text={'Breakdown of how tokensare distributed'} />
                    <div className='lg:w-1/2 md:w-11/12 w-full mx-auto mb-7 '>
                        <GradientText props={`Token Distribution`} />
                    </div>
                    <div className="lg:w-10/12 w-full -mt-12 mx-auto lg:-mt-36">
                        <img src={tokendistribution} className='w-full h-full' alt="" />
                    </div>
                </div> */}
                {/* <div className="lg:pt-[134px] md:pt-24 sm:pt-14 pt-10 wow animate__animated animate__fadeInUp">
                    <div className='lg:w-1/2 md:w-11/12 w-full mx-auto mb-7'>
                        <GradientText props={`Vesting Schedule`} />
                    </div>
                    <div className="lg:w-10/12 w-full mx-auto lg:-mt-10">
                        <img src={maprock} className='w-full h-full' alt="" />
                    </div>
                </div> */}
                {/* <div className='table-wrapper my-5'>
                    <div className='table-container'>
                        <div className="overflow-x-auto">
                            <table className="table-auto w-full lg:w-3/4 mx-4 lg:mx-auto border-spacing-y-2 border-separate">
                                <thead>
                                    <tr className=" rounded-md overflow-hidden h-53px">
                                        <th className="px-4 py-4 w-1/4 border-y-[#FFBE98]  border-r-0 border-l-[#FFBE98] rounded-l border text-start">
                                            <span className='text-[#FFBE98] pm text-lg text-start'>Purpose</span>
                                        </th>
                                        <th className="px-4 py-4 w-1/4 border-y-[#FFBE98] border-x-0 border">
                                            <span className='text-[#FFBE98] pm text-lg text-center'>Share</span>
                                        </th>
                                        <th className="px-4 py-4 w-1/4 border-y-[#FFBE98] border-x-0 border">
                                            <span className='text-[#FFBE98] pm text-lg text-center'>Cliff</span>
                                        </th>
                                        <th className="px-4 py-4 w-1/4 border-y-[#FFBE98]  border-l-0 border-r-[#FFBE98] rounded-r border">
                                            <span className='text-[#FFBE98] pm text-lg text-center'>Vesting</span>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className='border-spacing-y-2 border-separate'>
                                    <tr className="h-66px  !my-1">
                                        <td className="px-4 py-4 w-1/4 border-y-[#FFBE98] border-r-0 border-l-[#FFBE98] rounded-l  border text-start">
                                            <span className='text-white pr text-base  text-start'>
                                                Public
                                            </span>
                                        </td>
                                        <td className="px-4 py-4 w-1/4 border-y-[#FFBE98] border-x-0  border text-center">
                                            <span className='text-white pr text-base text-center'>
                                                45%
                                            </span>
                                        </td>
                                        <td className="px-4 py-4 w-1/4 border-y-[#FFBE98] border-x-0  border text-center">
                                            <span className='text-white pr text-base text-center'>
                                                0
                                            </span>
                                        </td>
                                        <td className="px-4 py-4 w-1/4 border-y-[#FFBE98] border-l-0 border-r-[#FFBE98] rounded-r  border text-center">
                                            <span className='text-white pr text-base text-center'>
                                                0
                                            </span>
                                        </td>
                                    </tr>
                                    <tr className="h-66px  !my-1">
                                        <td className="px-4 py-4 w-1/4 border-y-[#FFBE98] border-r-0 border-l-[#FFBE98] rounded-l  border text-start">
                                            <span className='text-white pr text-base text-start'>
                                                Team & Advisor
                                            </span>
                                        </td>
                                        <td className="px-4 py-4 w-1/4 border-y-[#FFBE98] border-x-0  border text-center">
                                            <span className='text-white pr text-base text-center'>
                                                15%
                                            </span>
                                        </td>
                                        <td className="px-4 py-4 w-1/4 border-y-[#FFBE98] border-x-0  border text-center">
                                            <span className='text-white pr text-base text-center'>
                                                12
                                            </span>
                                        </td>
                                        <td className="px-4 py-4 w-1/4 border-y-[#FFBE98] border-l-0 border-r-[#FFBE98] rounded-r  border text-center">
                                            <span className='text-white pr text-base text-center'>
                                                36
                                            </span>
                                        </td>
                                    </tr>
                                    <tr className="h-66px  !my-1">
                                        <td className="px-4 py-4 w-1/4 border-y-[#FFBE98] border-r-0 border-l-[#FFBE98] rounded-l  border text-start">
                                            <span className='text-white pr text-base text-start'>
                                                Rewards
                                            </span>
                                        </td>
                                        <td className="px-4 py-4 w-1/4 border-y-[#FFBE98] border-x-0  border text-center">
                                            <span className='text-white pr text-base text-center'>
                                                10%
                                            </span>
                                        </td>
                                        <td className="px-4 py-4 w-1/4 border-y-[#FFBE98] border-x-0  border text-center">
                                            <span className='text-white pr text-base text-center'>
                                                6
                                            </span>
                                        </td>
                                        <td className="px-4 py-4 w-1/4 border-y-[#FFBE98] border-l-0 border-r-[#FFBE98] rounded-r  border text-center">
                                            <span className='text-white pr text-base text-center'>
                                                60
                                            </span>
                                        </td>
                                    </tr>
                                    <tr className="h-66px  !my-1">
                                        <td className="px-4 py-4 w-1/4 border-y-[#FFBE98] border-r-0 border-l-[#FFBE98] rounded-l  border text-start">
                                            <span className='text-white pr text-base text-start'>
                                                Marketing
                                            </span>
                                        </td>
                                        <td className="px-4 py-4 w-1/4 border-y-[#FFBE98] border-x-0  border text-center">
                                            <span className='text-white pr text-base text-center'>
                                                10%
                                            </span>
                                        </td>
                                        <td className="px-4 py-4 w-1/4 border-y-[#FFBE98] border-x-0  border text-center">
                                            <span className='text-white pr text-base text-center'>
                                                0
                                            </span>
                                        </td>
                                        <td className="px-4 py-4 w-1/4 border-y-[#FFBE98] border-l-0 border-r-[#FFBE98] rounded-r  border text-center">
                                            <span className='text-white pr text-base text-center'>
                                                24
                                            </span>
                                        </td>
                                    </tr>
                                    <tr className="h-66px  !my-1">
                                        <td className="px-4 py-4 w-1/4 border-y-[#FFBE98] border-r-0 border-l-[#FFBE98] rounded-l  border text-start">
                                            <span className='text-white pr text-base text-start'>
                                                Partnership & Collaboration
                                            </span>
                                        </td>
                                        <td className="px-4 py-4 w-1/4 border-y-[#FFBE98] border-x-0  border text-center">
                                            <span className='text-white pr text-base text-center'>
                                                5%
                                            </span>
                                        </td>
                                        <td className="px-4 py-4 w-1/4 border-y-[#FFBE98] border-x-0  border text-center">
                                            <span className='text-white pr text-base text-center'>
                                                6
                                            </span>
                                        </td>
                                        <td className="px-4 py-4 w-1/4 border-y-[#FFBE98] border-l-0 border-r-[#FFBE98] rounded-r  border text-center">
                                            <span className='text-white pr text-base text-center'>
                                                24
                                            </span>
                                        </td>
                                    </tr>
                                    <tr className="h-66px  !my-1">
                                        <td className="px-4 py-4 w-1/4 border-y-[#FFBE98] border-r-0 border-l-[#FFBE98] rounded-l  border text-start">
                                            <span className='text-white pr text-base text-start'>
                                                Community & Eco System
                                            </span>
                                        </td>
                                        <td className="px-4 py-4 w-1/4 border-y-[#FFBE98] border-x-0  border text-center">
                                            <span className='text-white pr text-base text-center'>
                                                5%
                                            </span>
                                        </td>
                                        <td className="px-4 py-4 w-1/4 border-y-[#FFBE98] border-x-0  border text-center">
                                            <span className='text-white pr text-base text-center'>
                                                3
                                            </span>
                                        </td>
                                        <td className="px-4 py-4 w-1/4 border-y-[#FFBE98] border-l-0 border-r-[#FFBE98] rounded-r  border text-center">
                                            <span className='text-white pr text-base text-center'>
                                                60
                                            </span>
                                        </td>
                                    </tr>
                                    <tr className="h-66px  !my-1">
                                        <td className="px-4 py-4 w-1/4 border-y-[#FFBE98] border-r-0 border-l-[#FFBE98] rounded-l  border text-start">
                                            <span className='text-white pr text-base text-start'>
                                                Reserve
                                            </span>
                                        </td>
                                        <td className="px-4 py-4 w-1/4 border-y-[#FFBE98] border-x-0  border text-center">
                                            <span className='text-white pr text-base text-center'>
                                                10%
                                            </span>
                                        </td>
                                        <td className="px-4 py-4 w-1/4 border-y-[#FFBE98] border-x-0  border text-center">
                                            <span className='text-white pr text-base text-center'>
                                                6
                                            </span>
                                        </td>
                                        <td className="px-4 py-4 w-1/4 border-y-[#FFBE98] border-l-0 border-r-[#FFBE98] rounded-r  border text-center">
                                            <span className='text-white pr text-base text-center'>
                                                24
                                            </span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div> */}
                <div className="lg:pt-[125px] md:pt-24 sm:pt-14 pt-10 wow animate__animated animate__fadeInUp">
                    <TopHeading text={'Where to store $AB Tokens'} />
                    <div className='lg:w-1/2 md:w-11/12 w-full mx-auto mb-7'>
                        <GradientText props={`Storage`} />
                    </div>
                    <div className="lg:w-3/5 md:w-11/12 w-full mx-auto mt-20">
                        <div className='grid lg:grid-cols-5 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-10 lg:gap-5 justify-center items-center mx-auto wow animate__animated animate__fadeInUp'>
                            <div className="storageCard">
                                <a href='https://metamask.io/' target="_blank" rel="noopener noreferrer">
                                    <div className='lg:w-[150px] md:w-[120px] md:h-[120px] w-[100px] h-[100px] lg:h-[150px] mx-auto'>
                                        <img src={metamask} className='w-full h-full object-contain' alt="" />
                                    </div>
                                    <h3 className='text-lg/6 text-center text-white pr mt-[18px]'>Metamask</h3>
                                </a>
                            </div>
                            <div className="storageCard">
                                <a href='https://www.argent.xyz/' target="_blank" rel="noopener noreferrer">
                                    <div className='lg:w-[150px] md:w-[120px] md:h-[120px] w-[100px] h-[100px] lg:h-[150px] mx-auto'>
                                        <img src={argent} className='w-full h-full object-contain' alt="" />
                                    </div>
                                    <h3 className='text-lg/6 text-center text-white pr mt-[18px]'>Ledger</h3>
                                </a>
                            </div>
                            <div className="storageCard">
                                <a href='https://trustwallet.com/' target="_blank" rel="noopener noreferrer">
                                    <div className='lg:w-[150px] md:w-[120px] md:h-[120px] w-[100px] h-[100px] lg:h-[150px] mx-auto'>
                                        <img src={atomicwallet} className='w-full h-full object-contain' alt="" />
                                    </div>
                                    <h3 className='text-lg/6 text-center text-white pr mt-[18px]'>Trust Wallet</h3>
                                </a>
                            </div>
                            <div className="storageCard">
                                <a href='https://www.coinbase.com/' target="_blank" rel="noopener noreferrer">
                                    <div className='lg:w-[150px] md:w-[120px] md:h-[120px] w-[100px] h-[100px] lg:h-[150px] mx-auto'>
                                        <img src={coinbase} className='w-full h-full object-contain p-2' alt="" />
                                    </div>
                                    <h3 className='text-lg/6 text-center text-white pr mt-[18px]'>Coinbase</h3>
                                </a>
                            </div>
                            <div className="storageCard">
                                <a href='https://www.exodus.com/' target="_blank" rel="noopener noreferrer">
                                    <div className='lg:w-[150px] md:w-[120px] md:h-[120px] w-[100px] h-[100px] lg:h-[150px] mx-auto'>
                                        <img src={exdius} className='w-full h-full object-contain' alt="" />
                                    </div>
                                    <h3 className='text-lg/6 text-center text-white pr mt-[18px]'>Exodus</h3>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Tokenomics