import React, { useEffect } from 'react'
import Header from '../component/Header'
import { contribute2, linkedin, openPosition, personmem, temamember, twitterx } from '../services/image'
import TopHeading from '../component/TopHeading'
import GradientText from '../component/GradientText'
import Footer from '../component/Footer'
import CoreTeam from '../component/CoreTeam'
import AdvisoryBoardCard from '../component/AdvisoryBoardCard'
import { WOW } from 'wowjs'
import 'animate.css';
import { delay } from 'q'
import { useLocation } from 'react-router-dom'
let text = [1, 2, 3, 4, 5, 6];
let text2 = [1, 2, 3];
function Team() {

    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);



    useEffect(() => {
        const wow = new WOW(
            {
                boxClass: 'wow',      // default
                animateClass: 'animated', // default
                offset: 0,          // default
                mobile: true,       // default
                live: false        // default
            }
        )
        wow.init();
    }, [])
    return (
        <div className='Team_wrapper'>
            <div className="bg_team bg-size bg-no-repeat bg-center ">
                <div className='lg:mx-[59px] mx-4 '>
                    <Header />
                    <div className="lg:pt-56 pt-10 sm:pt-20 md:pt-32">
                        <div className="lg:w-3/5 md:w-11/12 w-full mx-auto flex flex-col justify-center items-center">
                            <h3 className='pr text-2xl text-white text-center mb-3.5 wow animate__animated animate__fadeInUp'>Core Team</h3>
                            <p className='text-grad7 text-trans text-4xl sm:text-[45px] md:text-[60px] lg:text-[74px]/[81px] pr text-center mb-8 wow animate__animated animate__fadeInUp'>A seasoned team with
                                proven past experience.</p>
                        </div>
                        <div className="lg:pt-40 md:pt-28 sm:pt-20 pt-10 ">
                            <div className="lg:w-3/4 w-full mx-auto">
                                <div className="grid gap-[30px] md:grid-cols-2 grid-cols-1 xl:grid-cols-3">
                                    {text2.map((items, idx) => {
                                        const delay = idx * 100 + "ms";
                                        return (
                                            <div className='md:p-[22px] p-3 rounded-[20px] relative bg-[#151524] group overflow-hidden wow animate__animated animate__fadeInUp' style={{ animationDelay: delay }}>
                                                <div className="h-[240px] ">
                                                    <img src={temamember} alt="" className='w-full h-full md:object-contain object-fill' />
                                                </div>
                                                <h3 className='mt-5 mb-1 text-grad5 text-trans text-2xl/8 pm'>Andrei Tara</h3>
                                                <p className='text-base/6 text-white pbd mb-5'>Founder & CEO</p>
                                                <p className='text-[#A7A8B1] line-clamp-5'>Tech Entrepreneur & Polymath.
                                                    Core Developer Elrond, Blockchain
                                                    Researcher SkyyNetwork. CEO &
                                                    Founder BuyPal, TLabs. BS CS, MSc
                                                    CS, PhD candidate CS.</p>
                                                <div className="absolute bg-[#1D1D2E] rounded-2xl flex flex-row items-center justify-start gap-3 py-3.5 px-[18px] bottom-0 z-10 left-0 right-0 transition-transform transform invisible group-hover:visible translate-y-full group-hover:translate-y-0">
                                                    <button className='border-none bg-[#FFBE98] rounded-full w-10 h-10 flex items-center justify-center '>
                                                        <div className='w-5 h-5'>
                                                            <img src={twitterx} className='w-full h-full' alt="" />
                                                        </div>
                                                    </button>
                                                    <button className='border-none bg-[#FFBE98] rounded-full w-10 h-10 flex items-center justify-center '>
                                                        <div className='w-5 h-5'>
                                                            <img src={linkedin} className='w-full h-full' alt="" />
                                                        </div>
                                                    </button>
                                                </div>
                                            </div>
                                        )
                                    })}


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='lg:mx-[59px] mx-4 lg:mb-20 md:mb-10 mb-5'>
                <div className="lg:w-3/4 w-full mx-auto mt-[30px]">
                    <div className="grid gap-[30px] md:grid-cols-2 grid-cols-1 xl:grid-cols-3">
                        {text2.map((items, idx) => {
                            const delay = idx * 100 + "ms";
                            return (
                                <div className="wow animate__animated animate__fadeInUp" style={{ animationDelay: delay }}>
                                    <CoreTeam />
                                </div>

                            )
                        })}


                    </div>
                </div>
            </div>
            <div className="lg:mx-[59px] mx-4">
                <div className="lg:pt-[70px] pt-4 ">
                    <div className='wow animate__animated animate__fadeInUp'>
                        <TopHeading text={'Advisory Board'} />
                    </div>
                    <div className='md:w-1/2 w-full mx-auto wow animate__animated animate__fadeInUp'>
                        <GradientText props={`People with relevant experience who support the team`} />
                    </div>
                </div>
                <div className='lg:mt-32 md:mt-16 mt-4 sm:mt-8'>
                    <div className='lg:w-3/4 w-full mx-auto'>
                        <div className="supprtTeam grid md:grid-cols-2 grid-cols-1 xl:grid-cols-3 gap-[30px] ">
                            {text.map((items, idx) => {
                                const delay = idx * 100 + "ms";
                                return (
                                    <div className="wow animate__animated animate__fadeInUp" style={{ animationDelay: delay }}>
                                        <AdvisoryBoardCard />
                                    </div> 
                                )
                            })}
                        </div>
                    </div>
                </div>
                <div className="careerbox">
                    <div className="lg:mt-32 md:mt-16 mt-8 lg:w-3/4  w-full mx-auto">
                        <div className="bg-careers h-72 sm:h-96 md:h-[404px] sm:p-5 p-3 md:p-10 bg-center bg-no-repeat">
                            <h3 className='text-white text-xl lg:text-2xl/8 pr wow animate__animated animate__fadeInUp'>Careers</h3>
                            <p className='mt-3.5 text-trans w-full sm:w-11/12 md:w-4/5 lg:w-3/4 text-grad12 text-2xl sm:text-4xl md:text-[40px] lg:text-[46px]/[51px] pr wow animate__animated animate__fadeInUp'>Become a contributor or see
                                open positions for core
                                contributors.</p>

                            <div className='gap-6 flex flex-row items-center mt-8 wow animate__animated animate__fadeInUp'>
                                <button className='h-[72px] w-[184px] '>
                                    <img src={contribute2} alt="" />
                                </button>
                                <button className='h-[72px] w-[184px] '>
                                    <img src={openPosition} alt="" />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Team