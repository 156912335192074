import PropTypes from 'prop-types'
import React, { memo, useEffect } from 'react'
import { window, bitget, hackgu, kucoin, mexc, pancakes, solidProof, solidProoSP } from '../services/image'
import { WOW } from 'wowjs'
import 'animate.css';
import { Link, NavLink } from 'react-router-dom'
import logEvent from '../analyticsUtils';
import { ArmaInstallerUrl } from '../env'

const Banner = memo((props) => {

    const handleBuyTokenClick = () => {
        logEvent('BuyNow_Btn_Clicked_Hero', { content_type: 'button', button: 'Buy Token', content_event: 'click' });
    };

    const handleJoinUsClick = () => {
        handleDownload();
        logEvent('JoinUs_Btn_Clicked_Hero', { button: 'Join Us', content_type: 'button', content_event: 'click' });
        // window.open("https://linktr.ee/ArmaBlock", "_blank");
    };

    useEffect(() => {
        logEvent('ArmaHero', { content_type: 'Hero Arma-Home', content_event: 'open Arma Hero Section' });

        const wow = new WOW(
            {
                boxClass: 'wow',      // default
                animateClass: 'animated', // default
                offset: 0,          // default
                mobile: true,       // default
                live: false        // default
            }
        )
        wow.init();
    }, []);

    const handleDownload = () => {
        // Create a new anchor element
        const anchor = document.createElement('a');
        // Set the href attribute to the file URL
        anchor.href = ArmaInstallerUrl;
        // Set the download attribute to the desired file name
        anchor.download = "Arma_Installer";
        // Append the anchor to the body
        document.body.appendChild(anchor);
        // Trigger a click event on the anchor
        anchor.click();
        // Remove the anchor from the body after a short delay
        setTimeout(() => {
            document.body.removeChild(anchor);
        }, 100);
    };

    return (
        <div className='md:mt-[50px] xl:mb-[80px] '>
            <div className="flex lg:flex-row flex-col gap-5 lg:gap-0 flex-1">
                <div className='flex flex-col'>
                    <p className='pr text-[#D9D9D9] tracking-[4%] text-base sm:text-lg md:text-xl lg:text-4xl/[56px] xl:w-[45%] lg:w-3/4 lg:text-start text-center'>The All New On-Chain Evolution</p>
                    <>
                        <h2 className=' xl:text-[80px]/[120px] text-[32px] sm:text-[45px] md:text-[60px] lg:text-[70px] tracking-[4%] pr lg:text-start text-center wow animate__animated animate__fadeInUp'>
                            <span className='text-[#FFBE98]'>
                                Arma:
                            </span>
                            <br />
                            <span className='text-[#FFBE98]'>The  Battle Ground</span>
                        </h2>
                        <p className='lg:text-2xl/9 lg:text-start text-center text-xl text-white pr mt-4 xl:w-3/5 lg:w-4/5 w-full'>Experience the Next Frontier
                            in Play-to-Earn Gaming</p>
                        <div className="flex sm:flex-row flex-col justify-center lg:justify-start  lg:mt-16 mt-4 gap-8 w-full">
                            <button className='clip flex items-center justify-center py-[18.5] px-12 h-[61px] rounded-lg lunch-game' onClick={handleJoinUsClick}>
                                <span className='xl:w-[22px] w-[20px] xl:h-[22px] h-4 mr-2'>
                                    <img src={window} alt="" />
                                </span>
                                <span className='text-white text-base pbd text-center'>Download</span>
                            </button>
                            <NavLink to='https://app.uniswap.org/explore/tokens/ethereum/0x84fad63f8f26335f4f1bebc9fbf5ba277fd23c9e' target='blank'>
                                <button className='clip flex items-center justify-center py-[18.5] px-12 h-[61px] rounded-lg bg-[#1E1E2D] w-full' onClick={handleBuyTokenClick}>
                                    <span className='text-white text-base pbd text-center'>Buy Now</span>
                                </button>
                            </NavLink>
                        </div>
                    </>
                </div>
                <div className='h-full flex  items-end self-end mx-auto wow animate__animated animate__fadeInUp'>
                    {/* <div>
                        <h3 className='text-2xl/6 text-center text-market mb-[15px] pr'>Available on</h3>
                        <div className='w-full flex flex-row lg:flex-nowrap justify-center  flex-wrap gap-2'>
                            <div className='md:w-[101px] md:h-[95px] sm:w-[80px] sm:h-[80px] w-12 h-12'><img src={kucoin} alt="" /></div>
                             <div className='md:w-[101px] md:h-[95px] sm:w-[80px] sm:h-[80px] w-12 h-12 '><img src={mexc} alt="" /></div>
                            <div className='md:w-[101px] md:h-[95px] sm:w-[80px] sm:h-[80px] w-12 h-12 '><img src={pancakes} alt="" /></div>
                            <div className='md:w-[101px] md:h-[95px] sm:w-[80px] sm:h-[80px] w-12 h-12 '><img src={bitget} alt="" /></div> 
                        </div>
                        <div className="mt-8 sm:flex-row flex-col flex gap-5">
                            {/* <div className='bg-[#20222A] w-[200px] rounded-xl py-[18px] flex flex-row justify-center gap-2 items-center h-[61px]'>
                                <img src={solidProoSP} alt="" className='w-[34px]' />
                                <div className=''>
                                    <p className='pr text-xs text-white mb-[0.5px]'>SOLID Proof</p>
                                    <p className='text-white text-[9px] pr mb-0'>Audited by</p>
                                </div>
                            </div>
                            <div className='bg-[#20222A] w-[200px] rounded-xl py-[18px] flex flex-row justify-center gap-2 items-center h-[61px]'>
                                <img src={hackgu} alt="" className='w-[34px] h-6' />
                                <div className=''>
                                    <p className='pr text-xs text-white mb-[0.5px]'>Hacken</p>
                                    <p className='text-white text-[9px] pr mb-0'>Audited by</p>
                                </div>
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
        </div>
    )
})

Banner.propTypes = {}

export default Banner
