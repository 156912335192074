import React from 'react'
import { Spinner } from '../services/image'

const Loader = () => {
  return (
    <div className='text-center bottom-auto mx-auto  text-4xl w-40 h-40' >
          <img src={Spinner} className='object-contain w-full h-full' alt="" />
    </div>
  )
}

export default Loader