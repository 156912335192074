import React, { useEffect, useState } from 'react'
import { useMedia } from 'react-use';
import { TokenStande, TokenStandeMobile, infoIcon } from '../services/image'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import EthereumSvg from '../assets/ethereum.svg'
import { baseUrl } from '../env'
import { Tooltip as ReactTooltip } from 'react-tooltip'
import 'react-tooltip/dist/react-tooltip.css'
import logEvent from '../analyticsUtils';
import { useLocation } from 'react-router-dom'

function TokenPresale() {
    const [copied, setCopied] = useState(false);
    const [presalePercent, setPresalePercent] = useState('')
    const isMobile = useMedia('(max-width: 868px)');
    const [isLoading, setIsLoading] = useState(true);
    const [tooltipWidth, setTooltipWidth] = useState('400px');
    const location = useLocation();

    const softCap = 16500000;
    const totalToken = 50000000;
    // Calculate the soft cap percentage
    // const softCapPercentage = (softCap / totalToken) * 100;
    const softCapPercentage = 23

    useEffect(() => {
        logEvent('Pre-Sale_page_view', {
            page_path: location.pathname,
            content_type: 'page',
            content_event: 'view'
        });
    }, []);

    const copyToClipboard = (text) => {
        logEvent('Wallet_Address_Copy_Btn_Clicked', { content_type: 'button', button: 'Wallet Copy Btn', content_event: 'click' });

        navigator.clipboard.writeText(text)
            .then(() => {
                setCopied(true);
                setTimeout(() => {
                    setCopied(false);
                }, 3000); // 3 seconds
            })
            .catch((error) => console.log(error));
    };

    const getPresaleData = async () => {
        await fetch(`${baseUrl}/get-presale-percent`, {
            method: 'POST',
        })
            .then(async (resp) => {
                if (!resp.ok) {
                    throw new Error(`HTTP error! Status: ${resp.status}`);
                }
                const resp2 = await resp.json();
                setPresalePercent(resp2.data.percent);
                setIsLoading(false);
            })
            .catch((error) => {
                console.error('Error:', error.message); // Log the error message
                setIsLoading(false);
            });
    }

    useEffect(() => {
        // Function to fetch presale data
        const fetchPresaleData = () => {
            getPresaleData();
        };

        // Call the fetchPresaleData function initially
        fetchPresaleData();

        // Set up a timer to call the fetchPresaleData function every 3 seconds
        const intervalId = setInterval(fetchPresaleData, 3000);

        // Add event listener for window resize
        const handleResize = () => {
            if (window.innerWidth <= 600) {
                setTooltipWidth('95vw'); // Set width to 90% of viewport width for mobile
            } else {
                setTooltipWidth('400px'); // Set default width for desktop
            }
        };
        window.addEventListener('resize', handleResize);

        // Cleanup function
        return () => {
            clearInterval(intervalId); // Clear the interval
            window.removeEventListener('resize', handleResize); // Remove the event listener
        };
    }, []);

    return (
        <div className='tokenpresale_wrapp  overflow-x-hidden'>
            <div className='flex p-8 flex-col-reverse lg:flex-row xl:p-24 lg:p-16 md:p-12'>
                <div className='xl:3/5 lg:w-3/4 flex flex-col justify-start'>
                    <div className='mb-10'>
                        <h1 className='text-white xl:text-6xl text-5xl xl:mb-10 mb-8 font-bold'>Token Pre-sale</h1>
                        <p className='text-white xl:text-base text-sm xl:mr-12'>Don't miss your shot at glory in "Arma: The Battle Ground" Purchasing SAB Tokens in our Pre-sale isn't just buying into a game-it's securing an elite status, unlocking exclusive gear, and commanding influence in Arma's universe. This is your gateway to becoming a foundational legend. Hesitate, and you risk watching from the sidelines. Step into the arena with SAB Tokens; your legend awaits.</p>
                    </div>
                    <div className='relative'>
                        {/* Progress bar */}
                        <div className="xl:w-11/12 w-[95%] h-4 bg-[#513322] rounded-full mt-8 relative">
                            <div className="absolute left-0 top-0 bg-[#F09E88] h-full rounded-full " style={{ width: `${presalePercent}%` }}></div>
                            <div className='flex absolute top-[-4px] ml-2 xl:ml-4' style={{ left: `${presalePercent - 3}%` }}>
                                <div className="bg-[#F09E88] h-[28px] rounded-full w-[28px] z-20 "></div>
                                <div className="absolute top-[6px] left-[6px] bg-[#ffffff] h-[16px] rounded-full w-[16px] z-20 "></div>
                            </div>
                        </div>
                        <div>
                            <div className='flex flex-col items-center absolute top-[-10px]' style={{ left: `${softCapPercentage}%` }}>
                                <p className='text-white xl:text-sm text-[12px] '>Soft Cap</p>
                                <div className='h-[50px] w-[2px] bg-white mt-1 mb-1'></div>
                                <p className='text-[#F09E88] xl:text-sm text-[12px]'>16,500,000 $AB</p>
                            </div>
                            <div className='flex flex-col items-center absolute top-[-10px] xl:right-[20px] md:right-[0px] right-[-15px]'>
                                <p className='text-white xl:text-sm text-[12px]'>Hard Cap</p>
                                <div className='h-[50px] w-[2px] bg-white mt-1 mb-1'></div>
                                <p className='text-[#F09E88] xl:text-sm text-[12px]'>50,000,000 $AB</p>
                            </div>
                        </div>
                    </div>
                    <div className='flex flex-col items-start mt-20'>
                        <div className='flex justify-center items-center'>
                            <h1 className='text-[#D6AC8F] xl:text-[40px] text-[30px]'>Total Pre-sale Tokens</h1>
                            {/* <GradientText className='text-left ' props={`Total Presale Tokens`} /> */}
                            <div className="tooltip" data-tooltip-id="info-data" data-tooltip-content="Participants wishing to join the pre-sale must transfer Ethers to the provided wallet address. The pre-sale will proceed on a First-Come, First-Serve (FCFS) basis.">
                                <img src={infoIcon} alt='info' className='text-white ml-4 xl:w-[25px] w-[20px]' />
                            </div>
                            <ReactTooltip id="info-data" place="right" style={{ fontSize: '14px', maxWidth: tooltipWidth, wordWrap: 'break-word', zIndex: '9999' }} />
                            {/* <FontAwesomeIcon icon={faInfoCircle} className='text-white ml-4 xl:fa-lg fa-md' /> */}
                        </div>
                        <h1 className='text-white xl:text-4xl text-2xl mb-10 font-bold'>50,000,000 $AB</h1>
                    </div>
                    <div>
                        <div>
                            <h1 className='text-white xl:text-2xl text-md'>Wallet Address</h1>
                            <div className="flex items-center">
                                <img src={EthereumSvg} className="mr-2 xl:w-[13px] w-[8px]" alt="Ethereum" />
                                <h2 className='text-[#D6AC8F] xl:text-2xl text-md font-bold'>0x445...F0B9</h2>
                                {/* <FontAwesomeIcon icon={faCopy} className="text-[#D6AC8F] ml-2" /> */}
                                <button onClick={() => copyToClipboard('0x4450BEEA7854e46fad82cA03fc0496061896F0B9')} className="text-[#D6AC8F] ml-2 focus:outline-none">
                                    <FontAwesomeIcon icon={faCopy} />
                                </button>
                                {copied && <span className="text-white ml-2 text-sm">Copied!</span>}
                            </div>
                            <div className="w-[130px] blur-border"></div>
                        </div>
                        <div className='flex xl:mt-10 mt-6 flex-col xl:flex-row md:flex-row'>
                            <div className='xl:mr-10 md:mr-8'>
                                <h1 className='text-white xl:text-2xl text-md'>Purchase Method</h1>
                                <div className="flex items-center">
                                    <img src={EthereumSvg} className="mr-2 xl:w-[13px] w-[8px]" alt="Ethereum" />
                                    <h2 className='text-[#D6AC8F] xl:text-2xl text-md font-bold'>Ethereum </h2>
                                </div>
                                <div className="w-[100px] blur-border"></div>
                            </div>
                            <div className='xl:mr-10 md:mr-8 xl:mt-0 md:mt-0 mt-6'>
                                <h1 className='text-white xl:text-2xl text-md'>Rate</h1>
                                <div className="flex items-center">
                                    <h2 className='text-[#D6AC8F] xl:text-2xl text-md font-bold'>$0.000425</h2>
                                </div>
                                <div className="w-[100px] blur-border"></div>
                            </div>
                            <div className='xl:mr-10 xl:mt-0 md:mt-0 mt-6'>
                                <h1 className='text-white xl:text-2xl text-md'>Max Per Wallet</h1>
                                <div className="flex items-center">
                                    <h2 className='text-[#D6AC8F] xl:text-2xl text-md font-bold'>$3000</h2>
                                </div>
                                <div className="w-[80px] blur-border"></div>
                            </div>
                        </div>
                    </div>
                    <div className='mt-10'>
                        <p className='text-[#9E826F]'>Note: Allocation of the tokens will take place 30 minutes before the Token Generation Event (TGE) is initiated.</p>
                    </div>
                </div>
                <div className={isMobile ? 'w-full flex justify-center' : 'xl:w-2/5 lg:w-1/4 flex justify-end'}>
                    {isMobile ? (
                        <img src={TokenStandeMobile} className='md:3/5 w-3/5 h-auto object-contain' alt="" />
                    ) : (
                        <img src={TokenStande} className='xl:w-4/5 lg:w-full h-auto object-contain' alt="" />
                    )}
                </div>
            </div>
        </div>
    )
}

export default TokenPresale