import React, { Suspense, useEffect, useState } from 'react'
import Header from '../component/Header'
import TopHeading from '../component/TopHeading'
import GradientText from '../component/GradientText'
import Footer from '../component/Footer';
import { CareerAnimation } from '../services/image'
// import PositionCard from '../component/PositionCard2';
import { WOW } from 'wowjs'
import logEvent from '../analyticsUtils';
import 'animate.css';
import { baseUrl } from '../env';
import PositonCard from '../component/PositonCard';
import PositionCard2 from '../component/PositionCard2';
import Loader from '../component/Loader';
import LottieLoader from '../component/LottieLoader';
import { useLocation } from 'react-router-dom';
import { callPreSaleApi } from '../component/GetSection';

// import PositonCard from '../component/PositonCard';
let text = [1, 2, 3, 4, 5, 6];
let text2 = [1, 2, 3, 4, 5];
let cardId = null;

function Careers() {
    const location = useLocation();
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    useEffect(() => {
        callPreSaleApi();
        logEvent('Career_page_view', {
            page_path: location.pathname,
            content_type: 'page',
            content_event: 'view'
        });
    }, []);

    useEffect(() => {
        const wow = new WOW(
            {
                boxClass: 'wow',      // default
                animateClass: 'animated', // default
                offset: 0,          // default
                mobile: true,       // default
                live: false        // default
            }
        )
        wow.init();
    }, []);

    useEffect(() => {
        setLoader(true)
        getAllData();

    }, [])

    const [pageData, setPageData] = useState(null);
    const [pageId, setPageId] = useState(null);
    const [loader, setLoader] = useState(false)

    const getAllData = async () => {

        await fetch(`${baseUrl}/get-career-details?page=1&limit=16`, {
            method: 'POST',

        })
            .then(async (resp) => {
                const resp2 = await resp.json();
                console.log('resprespresp', resp2)
                setPageData(resp2.data);
                setLoader(false)
            })
            .catch((err) => {
                console.log('eerr', err)
            })
            .finally(() => {
                setLoader(false)
            })
    }
    return (
        <div className='careers_Wrapper'>
            <div className="bg_team bg-size bg-no-repeat bg-center ">
                <div className='lg:mx-[59px] mx-4 '>
                    <Header />
                    {/* {loader == false ? */}
                    <div className="lg:pt-56 md:pt-28 sm:pt-14 pt-7">
                        <div className="lg:w-3/5 md:w-11/12 w-full mx-auto flex flex-col justify-center items-center">
                            <h3 className='pr text-2xl text-white text-center mb-3.5 wow animate__animated animate__fadeInUp'>Careers at Arma</h3>
                            <p className='text-grad7 text-trans text-4xl sm:text-[45px] md:text-[60px] lg:text-[74px]/[81px] pr text-center mb-8 wow animate__animated animate__fadeInUp'>Open positions in our
                                company</p>
                        </div>
                        {loader == false ?
                            <div className="lg:pt-40 md:pt-20 sm:pt-10 pt-6">
                                <div className="lg:w-3/4 md:w-11/12 w-full mx-auto">
                                    <div className="grid gap-[30px] lg:grid-cols-3 sm:grid-cols-2 grid-cols-1">
                                        {pageData && pageData.map((items, idx) => {
                                            const delay = idx * 100 + "ms";
                                            return (

                                                idx < 6 && <PositonCard items={items} delay={delay} />

                                            )
                                        })}

                                    </div>
                                </div>
                            </div>
                            : <LottieLoader />
                        }
                    </div>
                    {/* : <Loader />
                     } */}

                </div>
            </div>
            <div className="lg:mx-[59px] mx-4">
                <div className="lg:pt-20 md:pt-10 pt-5 ">
                    <TopHeading text={''} />
                    <div className='lg:w-1/2 w-full mx-auto wow animate__animated animate__fadeInUp'>
                        <GradientText props={`Design Department`} />
                    </div>
                    <div className="pt-11">
                        <div className="lg:w-3/4 md:w-11/12 w-full mx-auto">
                            {loader == false ?
                                <div className="flex flex-wrap flex-row justify-center gap-[30px]">
                                    {pageData && pageData.map((items, idx) => {
                                        const delay = idx * 100 + "ms";
                                        return (
                                            (5 < idx && idx < 11) && <div className="wow animate__animated animate__fadeInUp sm:w-[45%]  md:w-[31%]" style={{ animationDelay: delay }}>
                                                <PositionCard2 items={items} delay={delay} />
                                            </div>
                                        )
                                    })}


                                </div>
                                : <LottieLoader />
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className="lg:mx-[59px] mx-4">
                <div className="pt-[80px] wow animate__animated animate__fadeInUp">
                    <TopHeading text={''} />
                    <div className='w-1/2 mx-auto '>
                        <GradientText props={`Art Department`} />
                    </div>
                    <div className="pt-11">
                        <div className="lg:w-3/4 md:w-11/12 w-full mx-auto">
                            {loader == false ?
                                <div className="flex flex-wrap flex-row justify-center gap-[30px] grid-cols-3">
                                    {pageData && pageData.map((items, idx) => {
                                        const delay = idx * 100 + "ms";
                                        return (
                                            (idx > 10) && <div className="wow animate__animated animate__fadeInUp sm:w-[45%]  md:w-[31%]" style={{ animationDelay: delay }}>
                                                <PositionCard2 items={items} delay={delay} />
                                            </div>
                                        )
                                    })}

                                </div>
                                : <LottieLoader />
                            }
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Careers