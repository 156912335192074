import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { useGSAP } from "@gsap/react";
import { gsap } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import TopHeading from './TopHeading';
import GradientText from './GradientText';

function MobileSizeRoadmap(props) {

    // const productRoadMapcontainer = useRef();


    const applyShiningEffect = (ID) => {
        // console.log(`ID==>`, document.querySelector(`.newcard${ID}`));
        document.querySelector(`.newcard${ID}`).classList.remove('opacity-0');
        setTimeout(() => {
            document.querySelector(`.newcard${ID}`).classList.add('opacity-0');
        }, 4000);
    }

    return (
        <div className="lg:mx-[59px] mx-4">
            <div className="pt-10" >
                <TopHeading text={'Charting Tomorrow: A Blueprint for Battle and Beyond'} />
                <div className='md:w-[70%] w-full mx-auto'>
                    <GradientText props={`Product Roadmap`} />
                </div>
                <div className='flex w-full items-start justify-center mt-14 sm:mb-40 md:mb-[72px] mb-40'>
                    <div className='lg:w-[35%] md:w-[45%] relative ' >
                        <div className={`md:top-0 top-[69px] opacity-1  lg:right-[183px] md:right-[150px] lg:-left-[180px] md:-left-10 absolute overflow-hidden sm:left-[-220px] z-10 md:z-0 sm:right-[-220px] right-[-129px] left-[-140px] newcard1`}>
                            <div className={`roadmap_card  rounded-xl p-[1px] mx-auto`}>
                                <div className='bg-[#272135] rounded-xl py-5 px-6'>
                                    {/* <h3 className='text-white text-xl sm:text-[30px]/[45px] pr'>Year 1:</h3> */}
                                    {/* <p className='pr text-sm sm:text-base/6 text-white pb-3.5'>Q1 - 2023:</p> */}
                                    <ul className="pl-5 list-disc text-white">
                                        <li>
                                            <span className="text-xs sm:text-sm pr text-white">Conceptualize game universe and storyline.
                                            </span>
                                        </li>
                                        <li>
                                            <span className="text-xs sm:text-sm pr text-white">Establish core game mechanics.</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className='md:top-[460px] top-[626px] opacity-1  sm:right-[-14rem] sm:left-[-14rem] z-10 md:z-0 lg:right-[175px] md:right-[150px] lg:-left-[180px] md:-left-10 absolute overflow-hidden  right-[-8rem] left-[-9rem]  newcard3'>
                            <div className={` roadmap_card  rounded-xl p-[1px] mx-auto`}>
                                <div className='bg-[#272135] rounded-xl py-5 px-6'>
                                    {/* <h3 className='text-white text-xl sm:text-[30px]/[45px] pr'>Year 1</h3> */}
                                    {/* <p className='pr text-sm sm:text-base/6 text-white pb-3.5'>Q3 - 2023.</p> */}
                                    <ul className="pl-5 list-disc text-white">
                                        <li>
                                            <span className="text-xs sm:text-sm pr text-white">
                                                Initiate blockchain framework integration.
                                            </span>
                                        </li>
                                        <li>
                                            <span className="text-xs sm:text-sm pr text-white">Develop and internal test first set of in-game assets.</span>
                                        </li>
                                        <li>
                                            <span className="text-xs sm:text-sm pr text-white">Enhance player character customization options.</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className='md:top-[920px] sm:top-[1236px] opacity-1  sm:right-[-14rem] sm:left-[-14rem] z-10 md:z-0 lg:right-[175px] md:right-[150px] lg:-left-[180px] md:-left-10 absolute overflow-hidden top-[1236px] right-[-8rem] left-[-9rem]   newcard5'>
                            <div className={`roadmap_card  rounded-xl p-[1px] mx-auto`}>
                                <div className='bg-[#272135] rounded-xl py-5 px-6'>
                                    {/* <h3 className='text-white text-xl sm:text-[30px]/[45px] pr'>Year 2</h3> */}
                                    {/* <p className='pr text-sm sm:text-base/6 text-white pb-3.5'>Q1 - 2024:</p> */}
                                    <ul className="pl-5 list-disc text-white">
                                        <li>
                                            <span className="text-xs sm:text-sm pr text-white">Launch of official game website and community forums.
                                            </span>
                                        </li>
                                        <li>
                                            <span className="text-xs sm:text-sm pr text-white">Begin social media outreach and community building.
                                            </span>
                                        </li>
                                        <li>
                                            <span className="text-xs sm:text-sm pr text-white">Begin development of additional game maps.

                                            </span>
                                        </li>
                                        <li>
                                            <span className="text-xs sm:text-sm pr text-white">Introduction of first external partnerships.

                                            </span>
                                        </li>
                                        <li>
                                            <span className="text-xs sm:text-sm pr text-white">Marketing campaigns.
                                            </span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className='md:top-[1420px] sm:top-[1969px] opacity-1  sm:right-[-14rem] sm:left-[-14rem] z-10 md:z-0 lg:right-[175px] md:right-[150px] lg:-left-[180px] md:-left-10 absolute overflow-hidden top-[1969px] right-[-8rem] left-[-9rem]  newcard7'>
                            <div className={`roadmap_card  rounded-xl p-[1px] mx-auto`}>
                                <div className='bg-[#272135] rounded-xl py-5 px-6'>
                                    {/* <h3 className='text-white text-xl sm:text-[30px]/[45px] pr'>Year 2</h3> */}
                                    {/* <p className='pr text-sm sm:text-base/6 text-white pb-3.5'>Q3 - 2024:</p> */}
                                    <ul className="pl-5 list-disc text-white">
                                        <li>
                                            <span className="text-xs sm:text-sm pr text-white">Launch Beta version incorporating community feedback.
                                            </span>
                                        </li>
                                        <li>
                                            <span className="text-xs sm:text-sm pr text-white">Expansion of marketing campaigns.

                                            </span>
                                        </li>
                                        <li>
                                            <span className="text-xs sm:text-sm pr text-white">Development of in-game reward systems.
                                            </span>
                                        </li>
                                        <li>
                                            <span className="text-xs sm:text-sm pr text-white">Rollout of new blockchain-based player rewards.
                                            </span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className='md:top-[1920px] sm:top-[2800px] opacity-1  sm:right-[-14rem] sm:left-[-14rem] z-10 md:z-0 lg:right-[175px] md:right-[150px] lg:-left-[180px] md:-left-10 absolute overflow-hidden top-[2800px] right-[-8rem] left-[-9rem]  newcard9'>
                            <div className={`roadmap_card  rounded-xl p-[1px] mx-auto`}>
                                <div className='bg-[#272135] rounded-xl py-5 px-6'>
                                    {/* <h3 className='text-white text-xl sm:text-[30px]/[45px] pr'>Year 3</h3> */}
                                    {/* <p className='pr text-sm sm:text-base/6 text-white pb-3.5'>Q1 - 2025:</p> */}
                                    <ul className="pl-5 list-disc text-white">
                                        <li>
                                            <span className="text-xs sm:text-sm pr text-white">Finalize all game features for launch readiness.

                                            </span>
                                        </li>
                                        <li>
                                            <span className="text-xs sm:text-sm pr text-white">Strengthen and test all blockchain integrations.
                                            </span>
                                        </li>
                                        <li>
                                            <span className="text-xs sm:text-sm pr text-white">Finalize and polish player progression systems.
                                            </span>
                                        </li>
                                        <li>
                                            <span className="text-xs sm:text-sm pr text-white">Introduction of player leaderboards and ranking systems.
                                            </span>
                                        </li>
                                        <li>
                                            <span className="text-xs sm:text-sm pr text-white">Engage in cross-promotional activities with gaming communities.
                                            </span>
                                        </li>
                                        <li>
                                            <span className="text-xs sm:text-sm pr text-white">Strengthening community and developer communication channels.
                                            </span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className='md:top-[2490px] sm:top-[3700px] opacity-1  sm:right-[-14rem] sm:left-[-14rem] z-10 md:z-0 lg:right-[175px] md:right-[150px] lg:-left-[180px] md:-left-10 absolute overflow-hidden top-[3700px] right-[-8rem] left-[-9rem]  newcard11'>
                            <div className={`roadmap_card  rounded-xl p-[1px] mx-auto`}>
                                <div className='bg-[#272135] rounded-xl py-5 px-6'>
                                    {/* <h3 className='text-white text-xl sm:text-[30px]/[45px] pr'>Year 3</h3> */}
                                    {/* <p className='pr text-sm sm:text-base/6 text-white pb-3.5'>Q3 - 2025:</p> */}
                                    <ul className="pl-5 list-disc text-white">
                                        <li>
                                            <span className="text-xs sm:text-sm pr text-white">Launch celebration events in multiple regions.
                                            </span>
                                        </li>
                                        <li>
                                            <span className="text-xs sm:text-sm pr text-white">Implementation of advanced matchmaking algorithms. </span>
                                        </li>
                                        <li>
                                            <span className="text-xs sm:text-sm pr text-white">Advanced player analytics and feedback systems.
                                            </span>
                                        </li>
                                        <li>
                                            <span className="text-xs sm:text-sm pr text-white">Integration of augmented reality features.
                                            </span>
                                        </li>

                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className='md:top-[3090px] sm:top-[4460px] opacity-1  sm:right-[-14rem] sm:left-[-14rem] z-10 md:z-0 lg:right-[175px] md:right-[150px] lg:-left-[180px] md:-left-10 absolute overflow-hidden top-[4460px] right-[-8rem] left-[-9rem]  newcard13'>
                            <div className={`roadmap_card  rounded-xl p-[1px] mx-auto`}>
                                <div className='bg-[#272135] rounded-xl py-5 px-6'>
                                    {/* <h3 className='text-white text-xl sm:text-[30px]/[45px] pr'>Year 4</h3> */}
                                    {/* <p className='pr text-sm sm:text-base/6 text-white pb-3.5'>Q1 - 2026:</p> */}
                                    <ul className="pl-5 list-disc text-white">
                                        <li>
                                            <span className="text-xs sm:text-sm pr text-white">Release of seasonal in-game events and content.
                                            </span>
                                        </li>
                                        <li>
                                            <span className="text-xs sm:text-sm pr text-white">Introduction of second batch of character skins and weapons.</span>

                                        </li>
                                        <li>
                                            <span className="text-xs sm:text-sm pr text-white">Introduction of additional narrative-driven content.

                                            </span>
                                        </li>
                                        <li>
                                            <span className="text-xs sm:text-sm pr text-white">Implementation of community modding support.

                                            </span>
                                        </li>

                                        <li>
                                            <span className="text-xs sm:text-sm pr text-white">Inclusion of educational partnerships and platforms.
                                            </span>
                                        </li>

                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className='md:top-[3690px] sm:top-[5295px] opacity-1  sm:right-[-14rem] sm:left-[-14rem] z-10 md:z-0 lg:right-[175px] md:right-[150px] lg:-left-[180px] md:-left-10 absolute overflow-hidden top-[5295px] right-[-8rem] left-[-9rem]  newcard15'>
                            <div className={`roadmap_card  rounded-xl p-[1px] mx-auto`}>
                                <div className='bg-[#272135] rounded-xl py-5 px-6'>
                                    {/* <h3 className='text-white text-xl sm:text-[30px]/[45px] pr'>Year 4</h3> */}
                                    {/* <p className='pr text-sm sm:text-base/6 text-white pb-3.5'>Q3 - 2026:</p> */}
                                    <ul className="pl-5 list-disc text-white">
                                        <li>
                                            <span className="text-xs sm:text-sm pr text-white">Community-driven live events and competitions.
                                            </span>
                                        </li>
                                        <li>
                                            <span className="text-xs sm:text-sm pr text-white">Finding partners for global esports tournaments.</span>

                                        </li>
                                        <li>
                                            <span className="text-xs sm:text-sm pr text-white">Finalizing location for global esports tournaments.
                                            </span>
                                        </li>
                                        <li>
                                            <span className="text-xs sm:text-sm pr text-white">Preparation for hosting of global esports tournaments.
                                            </span>
                                        </li>

                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='big_vertical_line md:h-[4348px] h-[6000px] rounded-md w-[2px] relative '>
                        <div className='md:top-[50px] sm:top-0 top-0 box-shadow bg-[#FFCCC1] w-[220px] lg:w-[228px]   rounded-lg flex flex-col justify-center pt-1 pb-3  gap-2 -left-[114px] absolute cursor-pointer productRoadMap' onClick={() => { applyShiningEffect(1) }}>
                            <h5 className='text-[#6D7378] text-center text-sm pbd my-0'>Year 1</h5>
                            <p className='text-base text-[#231F33] text-center pbd'>Q1 : 2023</p>
                        </div>
                        <div className='md:top-[280px] top-[254px]  box-shadow bg-[#FFCCC1] w-[220px] lg:w-[228px]   rounded-lg flex flex-col justify-center pt-1 pb-3  gap-2 -left-[114px] absolute cursor-pointer productRoadMap2' onClick={() => { applyShiningEffect(2) }}>
                            <h5 className='text-[#6D7378] text-center text-sm pbd my-0'>Year 1</h5>
                            <p className='text-base text-[#231F33] text-center pbd'>Q2 : 2023</p>
                        </div>
                        <div className='md:top-[520px] top-[544px] box-shadow bg-[#FFCCC1] w-[220px] lg:w-[228px]   rounded-lg flex flex-col justify-center pt-1 pb-3  gap-2 -left-[114px] absolute cursor-pointer productRoadMap3' onClick={() => { applyShiningEffect(3) }}>
                            <h5 className='text-[#6D7378] text-center text-sm pbd my-0'>Year 1</h5>
                            <p className='text-base text-[#231F33] text-center pbd'>Q3 : 2023</p>
                        </div>
                        <div className='md:top-[755px] sm:top-[890px] top-[890px] box-shadow bg-[#FFCCC1] w-[220px] lg:w-[228px]   rounded-lg flex flex-col justify-center pt-1 pb-3  gap-2 -left-[114px] absolute cursor-pointer productRoadMap4' onClick={() => { applyShiningEffect(4) }}>
                            <h5 className='text-[#6D7378] text-center text-sm pbd my-0'>Year 1</h5>
                            <p className='text-base text-[#231F33] text-center pbd'>Q4 : 2023</p>
                        </div>
                        <div className='md:top-[1000px] sm:top-[1161px] top-[1161px] box-shadow bg-[#FFCCC1] w-[220px] lg:w-[228px] rounded-lg flex flex-col justify-center pt-1 pb-3  gap-2 -left-[114px] absolute cursor-pointer productRoadMap5' onClick={() => { applyShiningEffect(5) }}>
                            <h5 className='text-[#6D7378] text-center text-sm pbd my-0'>Year 2</h5>
                            <p className='text-base text-[#231F33] text-center pbd'>Q1 : 2024</p>
                        </div>
                        <div className='md:top-[1260px] sm:top-[1450px] top-[1564px] box-shadow bg-[#FFCCC1] w-[220px] lg:w-[228px]   rounded-lg flex flex-col justify-center pt-1 pb-3  gap-2 -left-[114px] absolute cursor-pointer productRoadMap6' onClick={() => { applyShiningEffect(6) }}>
                            <h5 className='text-[#6D7378] text-center text-sm pbd my-0'>Year 2</h5>
                            <p className='text-base text-[#231F33] text-center pbd'>Q2 : 2024</p>
                        </div>
                        <div className='md:top-[1500px] sm:top-[1895px] top-[1895px] box-shadow bg-[#FFCCC1] w-[220px] lg:w-[228px]   rounded-lg flex flex-col justify-center pt-1 pb-3  gap-2 -left-[114px] absolute cursor-pointer productRoadMap7' onClick={() => { applyShiningEffect(7) }}>
                            <h5 className='text-[#6D7378] text-center text-sm pbd my-0'>Year 2</h5>
                            <p className='text-base text-[#231F33] text-center pbd'>Q3 : 2024</p>
                        </div>

                        <div className='md:top-[1750px] sm:top-[2296px] top-[2296px] box-shadow bg-[#FFCCC1] w-[220px] lg:w-[228px]   rounded-lg flex flex-col justify-center pt-1 pb-3  gap-2 -left-[114px] absolute cursor-pointer productRoadMap8' onClick={() => { applyShiningEffect(8) }}>
                            <h5 className='text-[#6D7378] text-center text-sm pbd my-0'>Year 2</h5>
                            <p className='text-base text-[#231F33] text-center pbd'>Q4 : 2024</p>
                        </div>

                        <div className='md:top-[2000px] sm:top-[2725px] top-[2726px] box-shadow bg-[#FFCCC1] w-[220px] lg:w-[228px]   rounded-lg flex flex-col justify-center pt-1 pb-3  gap-2 -left-[114px] absolute cursor-pointer productRoadMap9' onClick={() => { applyShiningEffect(9) }}>
                            <h5 className='text-[#6D7378] text-center text-sm pbd my-0'>Year 3</h5>
                            <p className='text-base text-[#231F33] text-center pbd'>Q1 : 2025</p>
                        </div>

                        <div className='md:top-[2280px] sm:top-[3268px] top-[3269px] box-shadow bg-[#FFCCC1] w-[220px] lg:w-[228px]   rounded-lg flex flex-col justify-center pt-1 pb-3  gap-2 -left-[114px] absolute cursor-pointer productRoadMap10' onClick={() => { applyShiningEffect(10) }}>
                            <h5 className='text-[#6D7378] text-center text-sm pbd my-0'>Year 3</h5>
                            <p className='text-base text-[#231F33] text-center pbd'>Q2 : 2025</p>
                        </div>


                        <div className='md:top-[2560px] sm:top-[3626px] top-[3626px] box-shadow bg-[#FFCCC1] w-[220px] lg:w-[228px]   rounded-lg flex flex-col justify-center pt-1 pb-3  gap-2 -left-[114px] absolute cursor-pointer productRoadMap11' onClick={() => { applyShiningEffect(11) }}>
                            <h5 className='text-[#6D7378] text-center text-sm pbd my-0'>Year 3</h5>
                            <p className='text-base text-[#231F33] text-center pbd'>Q3 : 2025</p>
                        </div>

                        <div className='md:top-[2860px] sm:top-[4003px] top-[4003px] box-shadow bg-[#FFCCC1] w-[220px] lg:w-[228px]   rounded-lg flex flex-col justify-center pt-1 pb-3  gap-2 -left-[114px] absolute cursor-pointer productRoadMap12' onClick={() => { applyShiningEffect(12) }}>
                            <h5 className='text-[#6D7378] text-center text-sm pbd my-0'>Year 3</h5>
                            <p className='text-base text-[#231F33] text-center pbd'>Q4 : 2025</p>
                        </div>

                        <div className='md:top-[3160px] sm:top-[4382px] top-[4382px] box-shadow bg-[#FFCCC1] w-[220px] lg:w-[228px]   rounded-lg flex flex-col justify-center pt-1 pb-3  gap-2 -left-[114px] absolute cursor-pointer productRoadMap13' onClick={() => { applyShiningEffect(13) }}>
                            <h5 className='text-[#6D7378] text-center text-sm pbd my-0'>Year 4</h5>
                            <p className='text-base text-[#231F33] text-center pbd'>Q1 : 2026</p>
                        </div>

                        <div className='md:top-[3460px] sm:top-[4810px] top-[4810px] box-shadow bg-[#FFCCC1] w-[220px] lg:w-[228px]   rounded-lg flex flex-col justify-center pt-1 pb-3  gap-2 -left-[114px] absolute cursor-pointer productRoadMap14' onClick={() => { applyShiningEffect(14) }}>
                            <h5 className='text-[#6D7378] text-center text-sm pbd my-0'>Year 4</h5>
                            <p className='text-base text-[#231F33] text-center pbd'>Q2 : 2026</p>
                        </div>

                        <div className='md:top-[3750px] sm:top-[5220px] top-[5220px] box-shadow bg-[#FFCCC1] w-[220px] lg:w-[228px]   rounded-lg flex flex-col justify-center pt-1 pb-3  gap-2 -left-[114px] absolute cursor-pointer productRoadMap15' onClick={() => { applyShiningEffect(15) }}>
                            <h5 className='text-[#6D7378] text-center text-sm pbd my-0'>Year 4</h5>
                            <p className='text-base text-[#231F33] text-center pbd'>Q3 : 2026</p>
                        </div>
                        <div className='md:top-[4060px] sm:top-[5595px] top-[5595px] box-shadow bg-[#FFCCC1] w-[220px] lg:w-[228px]   rounded-lg flex flex-col justify-center pt-1 pb-3  gap-2 -left-[114px] absolute cursor-pointer productRoadMap16' onClick={() => { applyShiningEffect(16) }}>
                            <h5 className='text-[#6D7378] text-center text-sm pbd my-0'>Year 4</h5>
                            <p className='text-base text-[#231F33] text-center pbd'>Q4 : 2026</p>
                        </div>
                    </div>
                    <div className='lg:w-[35%] md:w-[45%] relative'>
                        <div className='md:top-[230px] top-[21rem] opacity-1  sm:-right-[13.5rem] sm:left-[-232px] md:-right-10 md:left-[160px] lg:-right-[180px]  z-10 md:z-0 absolute overflow-hidden -right-[8rem] left-[-148px] newcard2'>
                            <div className={`roadmap_card  rounded-xl p-[1px] mx-auto `}>
                                <div className='bg-[#272135] rounded-xl py-5 px-6'>
                                    {/* <h3 className='text-white text-xl sm:text-[30px]/[45px] pr'>Year 1</h3> */}
                                    {/* <p className='pr text-sm sm:text-base/6 text-white pb-3.5'>Q2 - 2023</p> */}
                                    <ul className="pl-5 list-disc text-white">
                                        <li>
                                            <span className="text-xs sm:text-sm pr text-white">Begin development of game maps.
                                            </span>
                                        </li>
                                        <li>
                                            <span className="text-xs sm:text-sm pr text-white">Initial design of character models and key environments.
                                            </span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className='md:top-[710px] sm:top-[969px] opacity-1  sm:right-[-14rem] sm:left-[-14rem]  md:-right-10 md:left-[160px] lg:-right-[180px]  z-10 md:z-0 absolute overflow-hidden top-[969px] left-[-9rem] right-[-8rem] newcard4'>
                            <div className={`roadmap_card  rounded-xl p-[1px] mx-auto`}>
                                <div className='bg-[#272135] rounded-xl py-5 px-6'>
                                    {/* <h3 className='text-white text-xl sm:text-[30px]/[45px] pr'>Year 1</h3> */}
                                    {/* <p className='pr text-sm sm:text-base/6 text-white pb-3.5'>Q4 - 2023:</p> */}
                                    <ul className="pl-5 list-disc text-white">
                                        <li>
                                            <span className="text-xs sm:text-sm pr text-white">Set up initial server architecture</span>
                                        </li>
                                        <li>
                                            <span className="text-xs sm:text-sm pr text-white">Conduct internal alpha testing of gameplay mechanics.</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className='md:top-[1195px] sm:top-[1616px] opacity-1  sm:right-[-14rem] sm:left-[-14rem]  md:-right-10 md:left-[160px] lg:-right-[180px]  z-10 md:z-0 absolute overflow-hidden top-[1640px] left-[-9rem] right-[-8rem] newcard6'>
                            <div className={`roadmap_card  rounded-xl p-[1px] mx-auto`}>
                                <div className='bg-[#272135] rounded-xl py-5 px-6'>
                                    {/* <h3 className='text-white text-xl sm:text-[30px]/[45px] pr'>Year 2</h3> */}
                                    {/* <p className='pr text-sm sm:text-base/6 text-white pb-3.5'>Q2 - 2024:</p> */}
                                    <ul className="pl-5 list-disc text-white">
                                        <li>
                                            <span className="text-xs sm:text-sm pr text-white">Public Alpha release for community feedback.
                                            </span>
                                        </li>
                                        <li>
                                            <span className="text-xs sm:text-sm pr text-white">First community event and feedback session.
                                            </span>
                                        </li>
                                        <li>
                                            <span className="text-xs sm:text-sm pr text-white">Implement initial blockchain-based transactions.
                                            </span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className='md:top-[1665px] sm:top-[2374px] opacity-1  sm:right-[-14rem] sm:left-[-14rem]  md:-right-10 md:left-[160px] lg:-right-[180px]  z-10 md:z-0 absolute overflow-hidden top-[2374px] left-[-9rem] right-[-8rem] newcard8'>
                            <div className={`roadmap_card  rounded-xl p-[1px] mx-auto`}>
                                <div className='bg-[#272135] rounded-xl py-5 px-6'>
                                    {/* <h3 className='text-white text-xl sm:text-[30px]/[45px] pr'>Year 2</h3> */}
                                    {/* <p className='pr text-sm sm:text-base/6 text-white pb-3.5'>Q4 - 2024:</p> */}
                                    <ul className="pl-5 list-disc text-white">
                                        <li>
                                            <span className="text-xs sm:text-sm pr text-white">Optimization and scaling of server infrastructure.
                                            </span>
                                        </li>
                                        <li>
                                            <span className="text-xs sm:text-sm pr text-white">Develop comprehensive player support framework.
                                            </span>
                                        </li>
                                        <li>
                                            <span className="text-xs sm:text-sm pr text-white">Establish dedicated player community teams.
                                            </span>
                                        </li>
                                        <li>
                                            <span className="text-xs sm:text-sm pr text-white">Introduction of educational and tutorial game modes.
                                            </span>
                                        </li>
                                        <li>
                                            <span className="text-xs sm:text-sm pr text-white">New character and map releases.
                                            </span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className='md:top-[2215px] sm:top-[3351px] opacity-1  sm:right-[-14rem] sm:left-[-14rem]  md:-right-10 md:left-[160px] lg:-right-[180px]  z-10 md:z-0 absolute overflow-hidden top-[3351px] left-[-9rem] right-[-8rem] newcard10'>
                            <div className={`roadmap_card  rounded-xl p-[1px] mx-auto`}>
                                <div className='bg-[#272135] rounded-xl py-5 px-6'>
                                    {/* <h3 className='text-white text-xl sm:text-[30px]/[45px] pr'>Year 3</h3> */}
                                    {/* <p className='pr text-sm sm:text-base/6 text-white pb-3.5'>Q2 - 2025:</p> */}
                                    <ul className="pl-5 list-disc text-white">
                                        <li>
                                            <span className="text-xs sm:text-sm pr text-white">Official global game launch.
                                            </span>
                                        </li>
                                        <li>
                                            <span className="text-xs sm:text-sm pr text-white">Initiate post-launch player feedback collection program.

                                            </span>
                                        </li>
                                        <li>
                                            <span className="text-xs sm:text-sm pr text-white">Expansion into new markets and languages.
                                            </span>
                                        </li>
                                        <li>
                                            <span className="text-xs sm:text-sm pr text-white">Enhanced in-game communication systems.

                                            </span>
                                        </li>

                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className='md:top-[2790px] sm:top-[4076px] opacity-1  sm:right-[-14rem] sm:left-[-14rem]  md:-right-10 md:left-[160px] lg:-right-[180px]  z-10 md:z-0 absolute overflow-hidden top-[4076px] left-[-9rem] right-[-8rem] newcard12'>
                            <div className={`roadmap_card  rounded-xl p-[1px] mx-auto`}>
                                <div className='bg-[#272135] rounded-xl py-5 px-6'>
                                    {/* <h3 className='text-white text-xl sm:text-[30px]/[45px] pr'>Year 3</h3> */}
                                    {/* <p className='pr text-sm sm:text-base/6 text-white pb-3.5'>Q4 - 2025:</p> */}
                                    <ul className="pl-5 list-disc text-white">
                                        <li>
                                            <span className="text-xs sm:text-sm pr text-white">Exploration of virtual reality integrations.
                                            </span>
                                        </li>
                                        <li>
                                            <span className="text-xs sm:text-sm pr text-white">Annual review and community summit.
                                            </span>
                                        </li>
                                        <li>
                                            <span className="text-xs sm:text-sm pr text-white">Preview of next year’s roadmap and upcoming features.
                                            </span>
                                        </li>
                                        <li>
                                            <span className="text-xs sm:text-sm pr text-white">Upgrades to in-game transaction and trading systems.</span>
                                        </li>

                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className='md:top-[3390px] sm:top-[4891px] opacity-1  sm:right-[-14rem] sm:left-[-14rem]  md:-right-10 md:left-[160px] lg:-right-[180px]  z-10 md:z-0 absolute overflow-hidden top-[4891px] left-[-9rem] right-[-8rem] newcard14'>
                            <div className={`roadmap_card  rounded-xl p-[1px] mx-auto`}>
                                <div className='bg-[#272135] rounded-xl py-5 px-6'>
                                    {/* <h3 className='text-white text-xl sm:text-[30px]/[45px] pr'>Year 4</h3> */}
                                    {/* <p className='pr text-sm sm:text-base/6 text-white pb-3.5'>Q2 - 2026:</p> */}
                                    <ul className="pl-5 list-disc text-white">
                                        <li>
                                            <span className="text-xs sm:text-sm pr text-white">Release of new game modes from community feedback.

                                            </span>
                                        </li>
                                        <li>
                                            <span className="text-xs sm:text-sm pr text-white">Partnership expansions for new in-game content.
                                            </span>
                                        </li>
                                        <li>
                                            <span className="text-xs sm:text-sm pr text-white">Continuous delivery of game patches and performance improvements.

                                            </span>
                                        </li>
                                        <li>
                                            <span className="text-xs sm:text-sm pr text-white">Development of additional player support tools.</span>
                                        </li>

                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className='md:top-[3990px] sm:top-[5674px]  sm:right-[-14rem] sm:left-[-14rem]  md:-right-10 md:left-[160px] lg:-right-[180px]  z-10 md:z-0 absolute overflow-hidden top-[5674px] left-[-9rem] right-[-8rem] newcard16'>
                            <div className={`roadmap_card  rounded-xl p-[1px] mx-auto`}>
                                <div className='bg-[#272135] rounded-xl py-5 px-6'>
                                    {/* <h3 className='text-white text-xl sm:text-[30px]/[45px] pr'>Year 4</h3> */}
                                    {/* <p className='pr text-sm sm:text-base/6 text-white pb-3.5'>Q2 - 2026:</p> */}
                                    <ul className="pl-5 list-disc text-white">
                                        <li>
                                            <span className="text-xs sm:text-sm pr text-white">Hosting of global esports tournaments.
                                            </span>
                                        </li>
                                        <li>
                                            <span className="text-xs sm:text-sm pr text-white">Launch of player content creation tools. </span>
                                        </li>
                                        <li>
                                            <span className="text-xs sm:text-sm pr text-white">Evaluation and expansion of game lore and universe.
                                            </span>
                                        </li>
                                        <li>
                                            <span className="text-xs sm:text-sm pr text-white">Expansion of player-driven storylines and events.
                                            </span>
                                        </li>
                                        <li>
                                            <span className="text-xs sm:text-sm pr text-white">Expansion of lore and universe through external media.
                                            </span>
                                        </li>

                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

MobileSizeRoadmap.propTypes = {}

export default MobileSizeRoadmap
