import React, { useRef, useEffect } from 'react';
import Header from '../component/Header';
import { ComingSoonBg } from '../services/image';
import { useLocation } from 'react-router-dom'
import logEvent from '../analyticsUtils';

const ComingSoon = () => {
    const location = useLocation();

    useEffect(() => {
        logEvent('Comingsoon_page_view', {
            page_path: location.pathname,
            content_type: 'page',
            content_event: 'view'
        });
    }, []);

    return (
        <div className="lg:px-[59px] px-4 flex flex-col justify-between h-screen bg-cover bg-center" style={{ backgroundImage: `url(${ComingSoonBg})`, overflow: 'hidden' }}>
            <Header />
            <div className="flex flex-col justify-center items-center h-full">
                <div className="text-white text-4xl sm:text-6xl md:text-8xl lg:text-9xl xl:text-10xl font-bold text-center mt-[-50px]">
                    Coming Soon
                </div>
            </div>
        </div>
    );
}

export default ComingSoon;