import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import { getAnalytics, isSupported } from "firebase/analytics";

const firebaseConfig = {
    apiKey: "AIzaSyD-u4FGsWMLml50ZhQ2148oGHaehkiIvSo",
    authDomain: "arma-f27fc.firebaseapp.com",
    projectId: "arma-f27fc",
    storageBucket: "arma-f27fc.appspot.com",
    messagingSenderId: "596961697232",
    appId: "1:596961697232:web:10b8f5711012866f76add5",
    measurementId: "G-G4SQP1XQKC"
};

const app = firebase.apps.length
    ? firebase.app()
    : firebase.initializeApp(firebaseConfig);

    let analytics = null;
    try {
        analytics = getAnalytics(app);
    } catch (error) {
        console.error("Error initializing Firebase Analytics:", error);
    }
    
    export { app, analytics };
    export const auth = app.auth();