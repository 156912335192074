import React, { useEffect } from 'react'
import * as THREE from "three"
import { useRef, useState } from "react"
import { Canvas, extend, useFrame } from "@react-three/fiber"
import { useTexture, shaderMaterial } from "@react-three/drei"
import { Map1, Map2, Map3 } from '../services/image'
import logEvent from '../analyticsUtils';

export const ImageFadeMaterial = shaderMaterial(
    {
        effectFactor: 1.2,
        dispFactor: 0,
        tex: undefined,
        tex2: undefined,
        disp: undefined
    },
    ` varying vec2 vUv;
    void main() {
      vUv = uv;
      gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
    }`,
    ` varying vec2 vUv;
    uniform sampler2D tex;
    uniform sampler2D tex2;
    uniform sampler2D disp;
    uniform float _rot;
    uniform float dispFactor;
    uniform float effectFactor;
    void main() {
      vec2 uv = vUv;
      vec4 disp = texture2D(disp, uv);
      vec2 distortedPosition = vec2(uv.x + dispFactor * (disp.r*effectFactor), uv.y);
      vec2 distortedPosition2 = vec2(uv.x - (1.0 - dispFactor) * (disp.r*effectFactor), uv.y);
      vec4 _texture = texture2D(tex, distortedPosition);
      vec4 _texture2 = texture2D(tex2, distortedPosition2);
      vec4 finalTexture = mix(_texture, _texture2, dispFactor);
      gl_FragColor = finalTexture;
      #include <tonemapping_fragment>
      #include <colorspace_fragment>
    }`
)

extend({ ImageFadeMaterial })

const MapImage = ({ image1, image2, image3, onAllImagesLoaded }) => {

    useEffect(() => {
        // Log game mode section view event when the component mounts
        logEvent('game_maps_section_view', {
            section_name: 'game_maps',
            content_type: 'section',
            content_event: 'view'
        });
    }, []);

    function FadingImage() {
        const ref = useRef()
        const [texture1, texture2, dispTexture] = useTexture([image1, image2, image3])
        const [hovered, setHover] = useState(false)
        useFrame(() => {
            ref.current.dispFactor = THREE.MathUtils.lerp(ref.current.dispFactor, hovered ? 1 : 0, 0.075)
        });

        return (
            <mesh onPointerOver={(e) => setHover(true)} onPointerOut={(e) => setHover(false)}  >
                <planeGeometry />
                <imageFadeMaterial ref={ref} tex={texture1} tex2={texture2} disp={dispTexture} toneMapped={false} />
            </mesh>
        )
    };

    function FadingImage2() {
        const ref = useRef()
        const [texture1, texture2, dispTexture] = useTexture([image1, image2, image3])
        const [hovered, setHover] = useState(false)
        useFrame(() => {
            ref.current.dispFactor = THREE.MathUtils.lerp(ref.current.dispFactor, hovered ? 1 : 0, 0.075)
        })
        return (
            <mesh onClick={(e) => setHover(hovered => !hovered)} >
                <planeGeometry />
                <imageFadeMaterial ref={ref} tex={texture1} tex2={texture2} disp={dispTexture} toneMapped={false} />
            </mesh>
        )
    };

    return (
        <>
            <div className='md:block hidden'>
                <Canvas camera={{ position: [0, 0, 2], fov: 20 }} style={{ width: 350, height: 350, borderRadius: 20, overflow: 'hidden' }}>
                    <FadingImage />
                </Canvas>
            </div>
            <div className='block md:hidden'>
                <Canvas camera={{ position: [0, 0, 2], fov: 20 }} style={{ width: 350, height: 350, borderRadius: 20, overflow: 'hidden' }}>
                    <FadingImage2 />
                </Canvas>
            </div>
        </>
    )
}

export default MapImage