import React, {useEffect} from 'react'
import Header from '../component/Header'
import Footer from '../component/Footer'
import { coinImage } from '../services/image'
import { useLocation } from 'react-router-dom'
import logEvent from '../analyticsUtils';

function Comesoon() {
    const location = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

      useEffect(() => {
        logEvent('Comesoon_page_view', {
            page_path: location.pathname,
            content_type: 'page',
            content_event: 'view'
        });
    }, []);

    return (
        <>
            {/* <div className='lg:px-[59px] px-4 pb-10 md:pb-0'>
            
            </div> */}
            <div className='bg-coming lg:px-[59px] px-4 pb-10 md:pb-0'  >
            <Header />
                <div className="flex items-center justify-start lg:pl-0 md:pl-10 sm:pl-4 pl-4 h-full xl:w-1/2 lg:w-[70%] md:w-[70%] w-full">
                    <div className='flex-col flex items-center justify-center'>
                        <article class="text-info">
                            <h2 className='lg:text-6xl text-center md:text-start text-5xl  pr mb-5 animator2 '>We're</h2>
                            <h2 className='pb-5 lg:text-7xl text-center md:text-start   text-5xl psbd  gap-4 animator2'>Coming  Soon
                            </h2>
                            <p className='text-white pr md:text-lg text-base m-2'>Hey gamers! We're gearing up to launch our exciting new gaming website. Drop your email below to be the first to know about our latest updates, exclusive offers, and epic launch events. Don't miss out on leveling up your gaming experience with us!</p>
                        </article>
                    </div>
                </div>
            </div>

            {/* <Footer /> */}

        </>
    )
}

export default Comesoon