import { baseUrl } from '../env';

export const callPreSaleApi = async () => {
    try {
        const response = await fetch(`${baseUrl}/get-sections`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
        });
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        
        const data = await response.json();
        // console.log('printing presale status', data);
    
        const presaleSection = data.data.find(section => section.name === 'presale');
        if (presaleSection) {
            // Store the status in local storage
            localStorage.setItem('presaleStatus', presaleSection.status);
            // Update the presale status in the component state
            return presaleSection.status;
            // setPresaleStatus(presaleSection.status);
        }
    
        // Handle the data or perform other actions based on the response
    } catch (error) {
        console.error('Error fetching data:', error);
        // Handle errors here
    }
};