import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import logEvent from '../analyticsUtils';

function BlogCard({ blog }) {
    const navigate = useNavigate();

    const handleClick = () => {
        logEvent('blog_learn_more_click', {
            blog_id: blog.id,
            content_type: 'button',
            content_event: 'click'
        });

        navigate('/BlogDetail', { state: { blog } });
        // console.log('printingblog', blog)
    };

    return (
        <div className='rounded-[30px] group/items relative overflow-hidden wow animate__animated animate__fadeInUp' style={{ animationDelay: blog.delay }} >
            {blog &&
                <>
                    <div>
                        <div className='h-[262px] w-full'>
                            <img src={blog.imageUrl} alt="" className='object-cover h-full w-full' />
                        </div>
                        <div className='px-10 pt-[22px] pb-[30px] bg-[#222233]'>
                            <div className="flex flex-row gap-10 mb-[15px]">
                                <span className='text-[#D9E1EA] text-base pr'>{blog.date}</span>
                                {/* <span className='text-[#D9E1EA] text-base pr'>{blog.readTime}</span> */}
                            </div>
                            <h3 className='text-2xl/8 pm text-white line-clamp-1'>{blog.title}</h3>
                        </div>
                    </div>
                    <div className='px-10 h-full pt-[42px] pb-[45px] bg-gradient-to-br absolute from-[#FFBE98] translate-y-0 group-hover/items:-translate-y-full transition-transform duration-500 ease-in-out to-[#511F1E]'>
                        <div className="flex flex-row gap-10 mb-[15px]">
                            <span className='text-[#D9E1EA] text-base pr'>{blog.date}</span>
                            {/* <span className='text-[#D9E1EA] text-base pr'>{blog.readTime}</span> */}
                        </div>
                        <h3 className='text-2xl/8 pm text-white line-clamp-1'>{blog.title}</h3>
                        <div className='mt-3.5 group-hover/items transition-all'>
                            <p className='text-[#D9E1EA] text-sm md:text-base/6 pr mb-5 overflow-hidden blog-card-content'>{blog.content}</p>
                            {/* <NavLink to={'/BlogDetail'} className='text-[#FFBE98] text-base/6 underline pm '>Learn More &gt;</NavLink> */}
                            <button className='text-[#FFBE98]' onClick={handleClick}>Learn More &gt;</button>
                            {/* <Link  className='text-[#FFBE98] text-base/6 underline pm '>Learn More &gt;</Link> */}
                        </div>
                    </div>
                </>
            }
        </div>
    );
}

export default BlogCard;