import { getAnalytics, logEvent as firebaseLogEvent } from 'firebase/analytics';
import { app } from './firebase.config'; // Importing firebase.config.jsx here

const logEvent = (eventName, eventData) => {
  const analytics = getAnalytics(app);
  try {
    firebaseLogEvent(analytics, eventName, eventData);
    // console.log(`Firebase Analytics event logged: ${eventName}`);
  } catch (error) {
    console.error(`Error logging Firebase Analytics event ${eventName}:`, error);
  }
};

export default logEvent;