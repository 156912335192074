
import './App.css';
import {
  BrowserRouter,
  createBrowserRouter,
  Route,
  RouterProvider,
  Routes,
} from "react-router-dom";
import Home from './page/Home';
import { register } from 'swiper/element/bundle';
import Team from './page/Team';
import Careers from './page/Careers';
import Docs from './page/Docs';
import BlogDetail from './page/BlogDetail';
import Tokenomics from './page/Tokenomics';
import Blog from './page/Blog';
import ComingSoon from './page/ComingSoon';
import Comesoon from './page/Comesoon';
import PrivacyPolicy from './page/privacypolicy';
import Tnc from './page/Tnc';
import Cookie from './page/Cookie';
import TokenPresale from './page/TokenPresalePage';
import { useEffect } from 'react';

register();
function App() {

  useEffect(() => {
    
}, [])

  return (
    <BrowserRouter>
      <div className='page-wrapper' id='page-wrapper'>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="Team" element={<Team />} />
          <Route path="Careers" element={<Careers />} />
          <Route path="Docs" element={<Docs />} />
          <Route path="BlogDetail" element={<BlogDetail />} />
          <Route path="Tokenomics" element={<Tokenomics />} />
          <Route path="Pre-sale" element={<TokenPresale />} />
          <Route path="Blog" element={<Blog />} />
          <Route path="PrivacyPolicy" element={<PrivacyPolicy />} />
          <Route path="Tnc" element={<Tnc />} />
          <Route path="Cookie" element={<Cookie />} />
          <Route path="Comingsoon" element={<ComingSoon />} />
          <Route path="/Comesoon" element={<Comesoon />} />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
