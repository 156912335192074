export const siteLogo = require('../assets/logo.png');
export const siteLogo2 = require('../assets/Arma.png');
export const telegram = require('../assets/telegram.webp');
export const twitter = require('../assets/twitter-x.webp');
export const pintrust = require('../assets/pinterst.webp');
export const rotate = require('../assets/rotate.webp');
export const uuper = require('../assets/uuper.webp');
export const dinos = require('../assets/dinos.webp');
export const window = require('../assets/windo.webp');
export const charactor1 = require('../assets/gamecharactor1.webp');
export const charactor2 = require('../assets/charactor2.jpg');
export const maps = require('../assets/maps.png')
export const btnback = require('../assets/bg-btn.png');
export const appmeme = require('../assets/Armer/2.png');
export const appmusic = require('../assets/Armer/4.png');
export const appqr = require('../assets/Armer/3.png');
export const appstable = require('../assets/Armer/1.png');
export const apptrand = require('../assets/Armer/5.png');
export const chemist = require('../assets/Armer/6.png');
export const postervideo = require('../assets/videothumbnail.jpg');
export const playbtnsvg = require('../assets/playbtn.png');
export const pancakes = require('../assets/pancakeswap.png');
export const kucoin = require('../assets/kucoin.png');
export const mexc = require('../assets/mexc.png');
export const bitget = require('../assets/bitget.png');
export const hackgu = require('../assets/hicsu.png');
export const api = require('../assets/api.png');
export const gsr = require('../assets/gsr.png');
export const charc3 = require('../assets/charc3.png');
export const bitcoin = require('../assets/bitcoin.png')
export const aplogo = require('../assets/ap-logo.png');
export const benzinga = require('../assets/benzinga.png');
export const insider = require('../assets/insider.png');
export const coinin = require('../assets/conin.png');
export const cryptomonde = require('../assets/cryptomonde.png');
export const microsoft = require('../assets/microsoft.png');
export const smart = require('../assets/smart.png');
export const altcoinbuzz = require('../assets/altcoinbuzz.png');

export const youtube = require('../assets/youtube.png')
export const discord = require('../assets/discord.png');
export const github = require('../assets/github.png');
export const insta = require('../assets/insta.png');
export const linkedin = require('../assets/linkedin.png');
export const lizert = require('../assets/lizert.png');
export const meta = require('../assets/meta.png');
export const telegram2 = require('../assets/telegram.png');
export const twitterx = require('../assets/twitterx.png');
export const metro = require('../assets/metro.png');
export const tiktok = require('../assets/tiktok.png');
export const temamember = require('../assets/teammember.png');
export const docsImage = require('../assets/homehero.png')
export const personmem = require('../assets/personmem.png');
export const bullets = require('../assets/bullets.png');
export const ship = require('../assets/ship.png');
export const guns = require('../assets/guns.png');
export const rifels = require('../assets/rifels.png');
export const tranding3 = require('../assets/trading.png');
export const trading4 = require('../assets/trading2.png');
export const bitcoin15 = require('../assets/500M.png');
export const ERC201 = require('../assets/ERC201.png');
export const GPTPLUS = require('../assets/GPTPLUS.png');
export const ABCoin = require('../assets/AB.png');
export const ETHEREUM = require('../assets/ETHEREUM.png');
export const NA = require('../assets/NA.png');
export const USDBUSD = require('../assets/USTBUSD.png');
export const OXED9BEB = require('../assets/OXED9BEB.png');
export const tokendistribution = require('../assets/tokendistribution.png');
export const maprock = require('../assets/maprock.png');
export const metamask = require('../assets/metamask.png');
export const argent = require('../assets/argent.png');
export const atomicwallet = require('../assets/atomicwallet.png');
export const coinbase = require('../assets/coinbase.png');
export const exdius = require('../assets/exdius.png');
export const battleground = require('../assets/battlegroud.jpg');
export const openPosition = require('../assets/Link_mask-group.png');

export const contribute2 = require('../assets/contribute2.png');



export const bitgit = require('../assets/bitgit.png');
export const chaingpt = require('../assets/chaingpt.png');
export const ejit = require('../assets/ejit.png');
export const hhh = require('../assets/hhh.png');
export const kucoin2 = require('../assets/kucoin2.png');
export const kucoin3 = require('../assets/kucoin2.png');
export const loack = require('../assets/loack.png');
export const mexc2 = require('../assets/mexc2.png');
export const pencake = require('../assets/pencake.png');
export const seedify = require('../assets/seedify.png');
export const pool = require('../assets/pool.png');
export const armaCharecter2 = require('../assets/ArmaCharecter.gif');


export const Spinner = require('../assets/Spinner.gif');

export const ArcticFrost = require('../assets/AssaultRifle/ArcticFrost.png');
export const BloodRedFury = require('../assets/AssaultRifle/BloodRedFury.png');
export const DesertCamo = require('../assets/AssaultRifle/DesertCamo.png');
export const ElectricBlue = require('../assets/AssaultRifle/ElectricBlue.png');
export const InfernoBlaze = require('../assets/AssaultRifle/InfernoBlaze.png');
export const MidnightOnyx = require('../assets/AssaultRifle/MidnightOnyx.png');

export const BlazeBreaker = require('../assets/Shotgun/BlazeBreaker.png');
export const CrimsonCarnage = require('../assets/Shotgun/CrimsonCarnage.png');
export const FrostbiteFang = require('../assets/Shotgun/FrostbiteFang.png');
export const RusticRaptor = require('../assets/Shotgun/RusticRaptor.png');
export const ShadowSerpent = require('../assets/Shotgun/ShadowSerpent.png');
export const StormSurge = require('../assets/Shotgun/StormSurge.png');

export const ArcticShatter = require('../assets/Grenade/ArcticShatter.png');
export const GoldenGrenade = require('../assets/Grenade/GoldenGrenade.png');
export const LethalLava = require('../assets/Grenade/LethalLava.png');
export const ObsidianOrb = require('../assets/Grenade/ObsidianOrb.png');
export const ShockwaveBlitz = require('../assets/Grenade/ShockwaveBlitz.png');
export const ToxicViper = require('../assets/Grenade/ToxicViper.png');

export const Mapt1 = require('../assets/Map/temp1.jpeg');
export const Mapt2 = require('../assets/Map/temp2.jpeg')
export const Map1 = require('../assets/Map/11.png');
export const Map2 = require('../assets/Map/22.jpg');
export const Map3 = require('../assets/Map/33.png');
export const Map4 = require('../assets/Map/44.png');
export const Map5 = require('../assets/Map/5.jpg');

export const SubterraLab = require('../assets/Map/SubterraLab.png');
export const IroncladBastion = require('../assets/Map/IroncladBastion.png');
export const NeonStreets = require('../assets/Map/NeonStreets.png');
export const SectorZero = require('../assets/Map/SectorZero.png');
export const SkylinePenthouse = require('../assets/Map/SkylinePenthouse.png')

export const MapName1 = require('../assets/Map Name/Ironclad Bastion.jpg');
export const MapName2 = require('../assets/Map Name/Neon Streets.jpg');
export const MapName3 = require('../assets/Map Name/Sector Zero.jpg');
export const MapName4 = require('../assets/Map Name/Skyline Penthouse.jpg');
export const MapName5 = require('../assets/Map Name/Subterra Lab.jpg');

export const Blog1 = require('../assets/BlogImages/1.jpg');
export const Blog2 = require('../assets/BlogImages/2.jpg');
export const Blog3 = require('../assets/BlogImages/3.jpg');
export const Blog4 = require('../assets/BlogImages/4.jpg');
export const Blog5 = require('../assets/BlogImages/5.jpg');
export const Blog6 = require('../assets/BlogImages/6.jpg');
export const Blog7 = require('../assets/BlogImages/7.jpg');
export const Blog8 = require('../assets/BlogImages/8.jpg');
export const Blog9 = require('../assets/BlogImages/9.jpg');
export const Blog10 = require('../assets/BlogImages/10.jpg');

export const solidProof = require('../assets/solidProof.png');
export const solidProoSP = require('../assets/SolidProofSP.png');

export const LoadingAnimation = require('../assets/loadingAnimation.json');
export const CareerAnimation = require('../assets/careerAnimation.json');
export const CareerAnimation1 = require('../assets/careerAnimation1.json');
export const TokenStande = require('../assets/TokenStande.png');
export const TokenStandeMobile = require('../assets/TokenStandeMobile.png');

export const infoIcon = require('../assets/info.png');
export const TelegramIcon = require('../assets/SocialMediaIcons/Telegram1.png');
export const TwitterIcon = require('../assets/SocialMediaIcons/ex.png');
export const TelegramCommunityIcon = require('../assets/SocialMediaIcons/Telegram Community_2.png');
export const DiscordIcon = require('../assets/SocialMediaIcons/Discord.png');
export const InstagramIcon = require('../assets/SocialMediaIcons/Insta.png');
export const FacebookIcon = require('../assets/SocialMediaIcons/FB.png');
export const MediumIcon = require('../assets/SocialMediaIcons/Medum.png');
export const EhterscanIcon = require('../assets/SocialMediaIcons/ehterscan.png');
export const LinktreeIcon = require('../assets/SocialMediaIcons/Linktree.png');
export const MailIcon = require('../assets/SocialMediaIcons/Mail.png');
export const YoutubeIcon = require('../assets/SocialMediaIcons/Youtube1.png');

export const ComingSoonBg = require('../assets/AARMABACKGROUND4.jpg');

export const AmazonAWS = require('../assets/Partners/AmazonAWS.png');
export const Blender = require('../assets/Partners/Blender.png');
export const BNB = require('../assets/Partners/BNB.png');
export const Coinbase = require('../assets/Partners/Coinbase.png');
export const Etherium = require('../assets/Partners/Etherium.png');
export const Exodus = require('../assets/Partners/Exodus.png');
export const Freepic = require('../assets/Partners/Freepic.png');
export const Hostinges = require('../assets/Partners/Hostinges.png');
export const Ledger = require('../assets/Partners/Ledger.png');
export const Metamak = require('../assets/Partners/Metamak.png');
export const Peragon = require('../assets/Partners/Peragon.png');
export const TrustWallet = require('../assets/Partners/Trust Wallet.png');
export const Unrea = require('../assets/Partners/Unrea.png');

export const ApexRivals = require('../assets/Gamemode/ApexRivals.jpg');
export const ZoneControl = require('../assets/Gamemode/ZoneControl.jpg');

export const ArmaCoinTokenomics = require('../assets/ArmaCoinTokenomics.png');