import React, { useEffect, useState } from 'react'
import { ArmaCoinTokenomics } from '../services/image'
import { Link, NavLink } from 'react-router-dom'

function TokenomicsBanner() {
    return (
        <>
            <div className='tokenomicbanner_wrapp  overflow-x-hidden'>
                <div className='flex p-4 flex-col-reverse lg:flex-row xl:p-2 lg:p-2 md:p-10 xl:pt-6 items-center lg:pt-10 md:pt-4 sm:pt-4 pt-0 '>
                    <div className='xl:w-[57%] lg:w-[60%] flex flex-col justify-start'>
                        <div>
                            <h2 className=' xl:text-[60px]/[90px] text-[32px] sm:text-[45px] md:text-[60px] lg:text-[50px] tracking-[4%] pr lg:text-start text-center wow animate__animated animate__fadeInUp'>
                                <span className='text-[#FFBE98]'>
                                Beyond Gaming:
                                </span>
                                <br />
                                <span className='text-[#FFBE98]'>The $AB Token Edge</span>
                            </h2>
                            <p className='lg:text-xl/7 lg:text-start text-center text-sm md:text-xl text-white pr mt-4 xl:w-9/7 lg:w-[90%] w-full'>
                            $AB Token is more than currency; it's power in your hands. Unlock exclusive rewards, shape the game, and earn as you conquer. Your adventure, your rewards. Welcome to the future of Play-2-Earn with $AB Token.</p>
                        </div>
                        {/* <div className="flex sm:flex-row flex-col justify-center lg:justify-start  lg:mt-16 md:mt-10 mt-8 gap-8">
                            <button className='clip flex items-center justify-center py-[18.5] px-12 h-[61px] rounded-lg lunch-game' onClick={() => window.open("https://linktr.ee/ArmaBlock", "_blank")}>
                                <span className='text-white text-base pbd text-center'>Join Us</span>
                            </button>
                            <NavLink to='/Comingsoon'>
                                <button className='clip flex items-center justify-center py-[18.5] px-12 h-[61px] rounded-lg bg-[#1E1E2D] w-full'>
                                    <span className='text-white text-base pbd text-center'>Buy Now</span>
                                </button>
                            </NavLink>
                        </div> */}
                    </div>
                    <div className={'xl:w-[43%] lg:w-[40%] flex justify-center '}>
                        <img src={ArmaCoinTokenomics} className='xl:w-full lg:w-full sm:w-[60%] w-[80%] h-auto object-contain' alt="" />
                    </div>
                </div>
            </div>
        </>
    )

}

export default TokenomicsBanner