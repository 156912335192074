import React, { useState } from 'react'
import { appmeme, appstable } from '../services/image'

function ApplicationCard(props) {
    const [flipped, setFlipped] = useState(false);
    const { delay } = props;
    const cardStyle = {
        '--filter-color': props.filterColor || 'rgba(255, 167, 141, 0.5)',
        animationDelay: delay
    };

    return (
        <div onMouseEnter={() => setFlipped(true)} onMouseLeave={() => setFlipped(false)} className={`jumpads-cards w-[143px] h-[194px] flex flex-col gap-3 items-center wow animate__animated animate__fadeInUp relative`} style={cardStyle}>
            <div className={`card-wrapper ${flipped ? 'flipped' : ''}`}>
                <div className='card-wrapper-front'>
                    <div className='lg:w-[90px] w-[90px] h-[90px] lg:h-[100px] flex items-center justify-center'>
                        <img src={props.img} alt="" />
                    </div>
                    <p className='md:text-base text-xs  text-center pbd text-white'>{props.text}</p>
                    <div className="absolute -bottom-4 left-3 h-[26px]  px-2  rounded-full bg-[#563D3C]">
                        <span className='text-sm text-center pr text-grad4 text-trans text-nowrap'>Available soon</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ApplicationCard