import React, { useState } from 'react';
import Modal from 'react-modal';
import { baseUrl } from '../env';
import logEvent from '../analyticsUtils';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: '#222233',
        maxWidth: 1000,
        // maxHeight: 500,
        overflow: 'auto',
        listStyleType: 'disc !important',
        paddingLeft: 40,
    },
}

function PositionCard2({ items, delay }) {

    // console.log(`items, delay==>`, items, delay);

    let subtitle;

    const [modalIsOpen, setIsOpen] = useState(false);

    function openModal(id) {
        setIsOpen(true);
    }

    function afterOpenModal() {
        // references are now sync'd and can be accessed.
        subtitle.style.color = '#f00';
    }

    function closeModal() {
        setIsOpen(false);
    }

    const [email, setEmail] = useState('');
    const [error, setError] = useState('');
    const [response, setResponse] = useState('');

    const handleSubmit = async (e) => {
        logEvent('Submit_Btn_Clicked_Career', { content_type: 'button', button: 'Submit', content_event: 'click' });

        e.preventDefault();

        if (!email) {
            setError('Please enter an email.');
            return;
        }

        // Basic email format validation
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email)) {
            setError('Please enter a valid email address.');
            return;
        }

        // If validation passes, make API call
        try {
            const response = await fetch(baseUrl + '/add-job-response', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ career_id: items.id, email: email }),
            });
            const data = await response.json();
            setResponse(data);
            setEmail('');
            setTimeout(() => {
                setResponse('');
            }, 5000);
            console.log(`data==>`, data);
        } catch (error) {
            console.error('Error:', error);
            // Handle API error
        }
    };

    const handleChange = (e) => {
        setEmail(e.target.value);
        setError(''); // Clear error message when user starts typing
    };

    const handleApplyNowClick = () => {
        // console.log('printing handleApplyNowClick')
        // Log Read More click event when the button is clicked
        logEvent('career_apply_now_click', {
            career_position: items.title,
            content_type: 'button',
            content_event: 'click'
        });
    };

    return (
        <>
            <Modal
                isOpen={modalIsOpen}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Description Modal"
            >

                <div className="flex justify-between items-center flex-row">
                    <h2 ref={(_subtitle) => (subtitle = _subtitle)} className=' text-xl pm !text-white'>Responsibilities</h2>
                    <button onClick={closeModal} className='text-red-500 text-base text-end'>X</button>
                </div>
                <div className='my-4 text-base text-center pr text-white' dangerouslySetInnerHTML={{ __html: `${items.description}` }}></div>
                <div>
                    <form onSubmit={handleSubmit} className='text-center'>
                        <input
                            className='p-3 border placeholder:text-gray-500 max-w-[300px] placeholder:text-base border-white rounded h-10 w-full'
                            placeholder='Enter Email'
                            value={email}
                            type='email'
                            required

                            onChange={handleChange}
                        />
                        <button className='mx-auto text-black psbd my-2 rounded applynowbtn  flex items-center justify-center py-2 px-4'>
                            Submit
                        </button>
                    </form>
                    {error && <p style={{ color: 'red' }} className='text-xl text-center'>{error}</p>}
                    {response && <p className='text-white text-center text-xl pm'>{response.message}</p>}
                </div>
            </Modal>
            <div className="postcard min-h-[266px] h-full w-full rounded-[30px] p-[1px] flex justify-center items-center">
                <div className="bg-[#222233] h-full  rounded-[30px] flex flex-col items-center justify-center w-full px-2.5 py-10">
                    <h3 className='text-grad9 text-trans text-[30px]/[45px] pr  text-center mb-10'>{items.title}</h3>
                    <button onClick={() => { openModal(items.id); handleApplyNowClick(); }} className='applynowbtn rounded-[20px] flex items-center justify-center py-4 px-7'>
                        <span className='text-[#0C0C15] text-center text-base/6 pbd'>Apply Now </span>
                    </button>
                </div>
            </div>
        </>

    )
}

export default PositionCard2