import React from 'react'
import { CareerAnimation1 } from '../services/image'
import Lottie from 'react-lottie';

const LottieLoader = () => {
    return (
        <div className='text-center flex justify-center items-center mx-auto  text-4xl w-full h-[500px]' >
            <Lottie
                options={{
                    loop: true,
                    autoplay: true,
                    animationData: CareerAnimation1 // Pass your animation JSON data
                }}
                width={900}
                height={'100vh'}
            />
        </div>
    )
}

export default LottieLoader