import PropTypes from 'prop-types'
import React, { memo, useEffect, useState } from 'react'
import { siteLogo, telegram, window, twitter, pintrust, rotate, uuper, dinos, TelegramIcon, TwitterIcon, DiscordIcon, InstagramIcon, FacebookIcon, TelegramCommunityIcon, MediumIcon, kucoin3, bitgit, mexc2, pancakes, chaingpt, ejit, seedify, pool, hhh, loack } from '../services/image'
import { Link, NavLink } from 'react-router-dom'
import Drawer from 'react-modern-drawer'
import 'react-modern-drawer/dist/index.css'
import SubmenuCard from './SubmenuCard'
import logEvent from '../analyticsUtils';
import { callPreSaleApi } from './GetSection';
import { ArmaInstallerUrl } from '../env'

const Header = memo((props) => {
    const [isOpen, setIsOpen] = useState(false)
    const [presaleStatus, setPresaleStatus] = useState(false);

    useEffect(() => {

        // Read the presale status directly from localStorage
        const status = localStorage.getItem('presaleStatus');
        if (status !== null) {
            setPresaleStatus(JSON.parse(status));
        }

        // Set up a timer to periodically check the presale status in localStorage
        const intervalId = setInterval(() => {
            const status = localStorage.getItem('presaleStatus');
            if (status !== null) {
                setPresaleStatus(JSON.parse(status)); // Convert to boolean
            }
        }, 3000); // Check every 3 seconds

        // Clean up function to clear the interval
        return () => clearInterval(intervalId);
    }, []);

    useEffect(() => {
        // Function to call the presale API
        const fetchPresaleStatus = () => {
            callPreSaleApi();
        };

        // Call the fetchPresaleStatus function initially
        fetchPresaleStatus();

        // Set up a timer to call the fetchPresaleStatus function every 3 seconds
        const intervalId = setInterval(fetchPresaleStatus, 3000);

        // Clean up function to clear the interval
        return () => clearInterval(intervalId);
    }, []);

    const toggleDrawer = () => {
        setIsOpen((prevState) => !prevState)
    }

    const handleBuyTokenClick = () => {
        logEvent('BuyToken_Btn_Clicked_Header', { content_type: 'button', button: 'Buy Token', content_event: 'click' });

    };

    const handleJoinUsClick = () => {
        handleDownload();
        logEvent('JoinUs_Btn_Clicked_Header', { button: 'Join Us', content_type: 'button', content_event: 'click' });
    };

    const handleSocialMediaClick = (socialMedia) => {
        // Log social media click event when a social media icon is clicked
        logEvent('social_media_click', {
            social_media: socialMedia,
            content_type: 'button',
            content_event: 'click'
        });
    };

    const handleDownload = () => {
        // Create a new anchor element
        const anchor = document.createElement('a');
        // Set the href attribute to the file URL
        anchor.href = ArmaInstallerUrl;
        // Set the download attribute to the desired file name
        anchor.download = "Arma_Installer";
        // Append the anchor to the body
        document.body.appendChild(anchor);
        // Trigger a click event on the anchor
        anchor.click();
        // Remove the anchor from the body after a short delay
        setTimeout(() => {
            document.body.removeChild(anchor);
        }, 100);
    };

    return (
        <div className='flex flex-row justify-between items-center gap-10  py-3.5 w-full'>
            <div className='flex flex-row items-center'>
                <div className='flex flex-row  items-center xl:gap-10 lg:gap-10'>
                    <NavLink to={'/'} className='lg:h-[100px] md:h-16 h-12  xl:w-40 lg:w-20 w-40'>
                        <img src={siteLogo} alt="site-logo" className='w-full h-full object-contain' />
                    </NavLink>
                    <div className='nav-item'>
                        <ul className='pl-0 list-none lg:flex hidden xl:gap-11 lg:gap-5 flex-row'>
                            <li className=''>
                                <NavLink to={'/'}>
                                    <span className='pr text-white xl:text-base lg:text-sm tracking-[4%]'>About Us</span>
                                </NavLink>
                            </li>
                            <li className=''>
                                <NavLink to={'/Careers'}>
                                    <span className='pr text-white xl:text-base lg:text-sm tracking-[4%]'>Careers</span>
                                </NavLink>
                            </li>
                            <li className=''>
                                <NavLink to={'https://drive.google.com/file/d/1Hg7cfnlgG_4O78bSPT1RKIYBrYwR54ub/view?usp=sharing'} target='blank'>
                                    <span className='pr text-white xl:text-base lg:text-sm tracking-[4%]'>Litepaper</span>
                                </NavLink>
                            </li>
                            <li className=''>
                                <NavLink to={'/Blog'}>
                                    <span className='pr text-white xl:text-base lg:text-sm tracking-[4%]'>Blog</span>
                                </NavLink>
                            </li>
                            <li className=''>
                                <NavLink to={'/Tokenomics'}>
                                    <span className='pr text-white xl:text-base lg:text-sm tracking-[4%]'>$AB</span>
                                </NavLink>

                            </li>
                            {presaleStatus && (
                                <li className=''>
                                    <NavLink to={'/Pre-sale'}>
                                        <span className='pr text-white xl:text-base lg:text-sm tracking-[4%]'>Pre-sale</span>
                                    </NavLink>
                                </li>
                            )}
                            {/* <li className='relative group/card'>
                                <div>
                                    <span className='pr text-white xl:text-base lg:text-sm tracking-[4%]'>OFN</span>
                                </div>
                                <div className='absolute top-full  group-hover/card:block hidden '>
                                    <SubmenuCard />
                                </div>
                            </li> */}
                        </ul>
                    </div>
                </div>
            </div>
            <div className='flex  flex-row items-center justify-end lg:gap-6 xl:gap-[38.88px]'>
                <div className='xl:flex hidden flex-row items-center gap-6'>
                    <a href="https://twitter.com/arma_block" target="_blank" className="xl:w-5 w-4" onClick={() => handleSocialMediaClick('twitter')}>
                        <img src={TwitterIcon} alt="twitter" />
                    </a>
                    <a href="https://t.me/armablock" target="_blank" className="xl:w-5 w-4" onClick={() => handleSocialMediaClick('telegram')}>
                        <img src={TelegramIcon} alt="telegram" />
                    </a>
                    <a href="https://t.me/armablockchat" target="_blank" className="xl:w-5 w-4" onClick={() => handleSocialMediaClick('telegram-community')}>
                        <img src={TelegramCommunityIcon} alt="telegram-community" />
                    </a>
                    <a href="https://discord.com/invite/V3EQBsFXEK" target="_blank" className="xl:w-5 w-4" onClick={() => handleSocialMediaClick('discord')}>
                        <img src={DiscordIcon} alt="discord" />
                    </a>
                    <a href="https://www.instagram.com/arma_block/" target="_blank" className="xl:w-5 w-4" onClick={() => handleSocialMediaClick('instagram')}>
                        <img src={InstagramIcon} alt="instagram" />
                    </a>
                    <a href="https://medium.com/@Arma_Block" target="_blank" className="xl:w-5 w-4" onClick={() => handleSocialMediaClick('medium')}>
                        <img src={MediumIcon} alt="medium" />
                    </a>
                    {/* <a href="https://www.facebook.com/profile.php?id=61557821586012" target="_blank" className="xl:w-5 w-4">
                        <img src={FacebookIcon} alt="facebook" />
                    </a> */}
                </div>
                <div className='lg:flex hidden flex-row items-center gap-[22px]'>
                    <NavLink to={'https://app.uniswap.org/explore/tokens/ethereum/0x84fad63f8f26335f4f1bebc9fbf5ba277fd23c9e'} target='blank'>
                        <button className=' game_download clip w-32 xl:h-[61px] lg:h-[52px] p-[1px] overflow-hidden rounded-md' onClick={handleBuyTokenClick}>
                            <div className='w-full h-full flex flex-row items-center justify-center   rounded-md  clip game_download2 p-2'>
                                <span className='text-[#FF9B97] text-base pbd tracking-wide'>Buy Token</span>
                            </div>
                        </button>
                    </NavLink>
                    <NavLink>
                        <button className='clip game_download rounded-lg flex gap-1.5 items-center justify-center xl:h-[61px] lg:h-[52px] px-4 rounded-br-[24px] p-3' onClick={handleJoinUsClick}>
                            <span className='xl:w-[22px] w-[20px] xl:h-[22px] h-4'>
                                <img src={window} alt="" />
                            </span>
                            <span className='xl:text-base text-sm text-white text-center pbd tracking-wide'>Download</span>
                        </button>
                    </NavLink>
                </div>
                <button className="w-5 h-5 lg:hidden block" onClick={toggleDrawer}>
                    <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                        width="20px" height="20px" viewBox="0 0 4096.000000 4096.000000"
                        preserveAspectRatio="xMidYMid meet">
                        <g transform="translate(0.000000,4096.000000) scale(0.100000,-0.100000)"
                            fill="#FFFFFF" stroke="none">
                            <path d="M2970 37669 c-780 -74 -1507 -423 -2049 -984 -446 -462 -748 -1046
    -865 -1674 -79 -420 -72 -896 21 -1311 170 -769 602 -1441 1228 -1910 514
    -385 1096 -603 1732 -650 106 -8 5044 -10 17543 -8 16531 3 17401 4 17510 21
    428 65 736 160 1073 328 613 308 1137 832 1445 1445 342 681 437 1454 272
    2199 -251 1129 -1084 2043 -2189 2399 -223 72 -473 123 -716 146 -134 12
    -34874 12 -35005 -1z"/>
                            <path d="M3080 24569 c-673 -40 -1318 -288 -1850 -709 -133 -105 -411 -383
    -519 -520 -696 -878 -896 -2055 -531 -3115 99 -285 207 -502 381 -760 311
    -461 725 -828 1219 -1079 366 -186 696 -287 1125 -343 135 -17 761 -18 17575
    -18 16590 0 17442 1 17573 18 723 92 1340 375 1855 850 1023 943 1335 2436
    777 3723 -105 243 -266 511 -439 729 -102 128 -332 363 -460 468 -553 459
    -1199 713 -1926 757 -196 12 -34584 11 -34780 -1z"/>
                            <path d="M3020 11459 c-1389 -112 -2556 -1083 -2910 -2424 -119 -449 -141
    -920 -64 -1385 90 -546 303 -1032 650 -1480 117 -153 407 -441 562 -560 460
    -354 964 -569 1552 -662 l145 -23 17520 0 c16808 0 17525 1 17640 18 735 110
    1342 404 1849 897 543 528 871 1186 973 1955 21 159 24 609 5 755 -34 261 -76
    452 -148 672 -280 859 -924 1577 -1746 1950 -314 142 -601 222 -1008 280 -66
    9 -3876 12 -17500 14 -9578 1 -17462 -3 -17520 -7z"/>
                        </g>
                    </svg>
                </button>
                <div className='lg:hidden'>
                    <Drawer
                        open={isOpen}
                        onClose={toggleDrawer}
                        direction='right'
                        className='bg-[#0b0c17] p-10 '
                    >
                        <NavLink to={'/'} className='mx-auto mb-10'>
                            <img src={siteLogo} alt="site-logo" className='w-20 h-12 object-contain mx-auto mb-10' />
                        </NavLink>
                        <ul className='pl-0 list-none justify-center items-center flex flex-col gap-5 '>
                            {/* <li className=''>
                                <NavLink to={'/Team'}>
                                    <span className='pr text-white xl:text-base lg:text-sm tracking-[4%]'>Team</span>
                                </NavLink>
                            </li> */}
                            <li className=''>
                                <NavLink to={'/Careers'}>
                                    <span className='pr text-white xl:text-base lg:text-sm tracking-[4%]'>Careers</span>
                                </NavLink>
                            </li>
                            <li className=''>
                                <NavLink to={'https://drive.google.com/file/d/1Hg7cfnlgG_4O78bSPT1RKIYBrYwR54ub/view?usp=sharing'} target='blank'>
                                    <span className='pr text-white xl:text-base lg:text-sm tracking-[4%]'>Litepaper</span>
                                </NavLink>
                            </li>
                            <li className=''>
                                <NavLink to={'/Blog'}>
                                    <span className='pr text-white xl:text-base lg:text-sm tracking-[4%]'>Blog</span>
                                </NavLink>
                            </li>
                            <li className=''>
                                <NavLink to={'/Tokenomics'}>
                                    <span className='pr text-white xl:text-base lg:text-sm tracking-[4%]'>$AB</span>
                                </NavLink>
                            </li>
                            {presaleStatus && (
                                <li className=''>
                                    <NavLink to={'/Pre-sale'}>
                                        <span className='pr text-white xl:text-base lg:text-sm tracking-[4%]'>Pre-sale</span>
                                    </NavLink>
                                </li>
                            )}
                            {/* <li className='relative group/card'>
                            <div>
                                <span className='pr text-white xl:text-base lg:text-sm tracking-[4%]'>OFN</span>
                            </div>
                            <div className='absolute   group-hover/card:block hidden left-[-11rem] top-[-16rem]'>
                                <SubmenuCard />
                            </div>

                        </li> */}
                        </ul>
                    </Drawer>
                </div>

            </div>

        </div>
    )
})

Header.propTypes = {}

export default Header
