import React, { useState, useEffect } from 'react'
import { bitcoin, hackgu, siteLogo, TelegramIcon, TwitterIcon, DiscordIcon, siteLogo2, InstagramIcon, FacebookIcon, MediumIcon, EhterscanIcon, LinktreeIcon, MailIcon, YoutubeIcon, TelegramCommunityIcon, solidProoSP } from '../services/image'
import { baseUrl } from '../env'
import logEvent from '../analyticsUtils';
import { callPreSaleApi } from './GetSection';

function Footer() {
    const [email, setEmail] = useState('');
    const [error, setError] = useState('');
    const [showDialog, setShowDialog] = useState(false);
    const [presaleStatus, setPresaleStatus] = useState(false);

    useEffect(() => {
        callPreSaleApi();
    }, []);

    useEffect(() => {

        // Read the presale status directly from localStorage
        const status = localStorage.getItem('presaleStatus');
        if (status !== null) {
            setPresaleStatus(JSON.parse(status));
        }

        // Set up a timer to periodically check the presale status in localStorage
        const intervalId = setInterval(() => {
            const status = localStorage.getItem('presaleStatus');
            if (status !== null) {
                setPresaleStatus(JSON.parse(status)); // Convert to boolean
            }
        }, 3000); // Check every 3 seconds

        // Clean up function to clear the interval
        return () => clearInterval(intervalId);
    }, []);

    const handleInputChange = (e) => {
        setEmail(e.target.value);
        setError(null); // Set error to null when input value changes
    };

    function redirectToSection(sectionId) {

        logEvent('footer_navigation_link_click', {
            section_name: sectionId,
            content_type: 'link',
            content_event: 'click'
        });
        // Check if the current page is the homepage
        const isHomePage = window.location.pathname === '/';

        if (isHomePage) {
            // Scroll to the specified section ID
            const section = document.getElementById(sectionId);
            if (section) {
                section.scrollIntoView({ behavior: 'smooth' });
            } else {
                // console.error(`Section with ID "${sectionId}" not found.`);
            }
        } else {
            // Redirect to the homepage with the section ID as a fragment identifier
            window.location.href = `/`;
        }
    }

    const handleSignUp = async () => {
        logEvent('Subscribe_Btn_Clicked', { content_type: 'button', button: 'newsletter-subscribe ', content_event: 'click' });

        if (!email.trim()) {
            setError('Email is required');
            return;
        } else {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!emailRegex.test(email)) {
                setError('Invalid email address.');
                return;
            }
        }

        try {
            const response = await fetch(`${baseUrl}/add-newsletter-subscriber`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email }),
            });

            if (response.status === 201) {
                // setSuccessMsg('You have successfully signed up!');
                setShowDialog(true);
            } else if (response.status === 420) {
                setError('Something went wrong, Code:420');
            } else {
                const responseData = await response.json();
                setError(`Error: ${response.status} - ${responseData.message}`);
            }
        } catch (error) {
            setError('An error occurred while signing up.');
        }
    };

    const handleCloseDialog = () => {
        setShowDialog(false);
    };

    const handleSocialMediaClick = (socialMedia) => {
        // Log social media click event when a social media icon is clicked
        logEvent('social_media_click', {
            social_media: socialMedia,
            content_type: 'button',
            content_event: 'click'
        });
    };

    const handleLinkClick = (linkName) => {
        // Log link click event when a link is clicked
        logEvent('footer_link_click', {
            link_name: linkName,
            content_type: 'link',
            content_event: 'click'
        });
    };

    const handleExternalLinkClick = (pagename) => {
        // Log external link click event when an external link is clicked
        logEvent('footer_external_link_click', {
            page_name: pagename,
            content_type: 'link',
            content_event: 'click'
        });
    };


    return (
        <div className='Arma_updates'>
            <div className='bg-spring md:bg-cover bg-contain bg-center bg-no-repeat mt-[65px] sm:pt-24 pt-10'>
                <div className="flex md:flex-row flex-col items-center  justify-center mx-14 lg:pb-[110px] md:pb-14 sm:pb-7 pb-7">
                    <div className="md:w-1/2 w-full">
                        <div className='lg:w-[55%] sm:w-1/2 mx-auto'>
                            <img src={bitcoin} className='w-full h-full object-contain' alt="" />
                        </div>
                    </div>
                    <div className="md:w-1/2 w-full">
                        <h3 className='pr text-base md:text-2xl/[60px] text-white'>Join the Battle: Stay Updated!</h3>
                        <p className='lg:w-3/4 w-full text-trans text-grad5 pr md:text-3xl sm:text-2xl text-xl lg:text-4xl xl:text-[40px]/[63px]'>Subscribe for Exclusive
                            Updates & Insights.</p>
                        <div className="lg:w-3/4 w-full flex sm:flex-row flex-col mt-[30px] gap-5 ">
                            <div className='flex-1'>
                                <input
                                    type="text"
                                    className='bg-white placeholder:text-base placeholder:text-[#06050c] xl:py-6 xl:px-8 lg:py-2 lg:px-2 md:py-2 md:px-2 py-2 px-4 h-[72px] w-full rounded-lg'
                                    placeholder='Enter Your Email'
                                    value={email}
                                    onChange={handleInputChange}
                                />
                                {error && <p className="text-red-500 mt-1 ml-2 xl:text-[14px] lg:text-[10px] md:text-[10px] text-[12px]">{error}</p>}
                                {/* {successMsg && <p className="text-green-500">{successMsg}</p>} */}
                            </div>
                            <button
                                className='clip game_download rounded-lg flex gap-1.5 items-center justify-center h-[72px] px-5  rounded-br-[24px]'
                                onClick={handleSignUp}
                            >
                                <span className='text-base text-white text-center pbd tracking-wide'>Sign up now</span>
                            </button>
                        </div>
                    </div>
                </div>
                <div className="flex md:flex-row lg:flex-nowrap flex-wrap flex-col items-start justify-between lg:mx-14 md:mx-7 mx-4 lg:gap-0 gap-10">
                    <div className='logo-footer xl:w-1/5 lg:w-1/6 md:w-1/5 w-1/4 mx-auto'>
                        <img src={siteLogo2} alt="" className='w-full h-full object-contain' />
                    </div>
                    <div className='lg:w-1/2 w-full  flex flex-col justify-end'>
                        <div className="flex flex-row flex-wrap lg:flex-nowrap xl:gap-10 lg:gap-10 gap-6 justify-center">
                            <div>
                                <ul>
                                </ul>
                            </div>
                            <div>
                                <ul>
                                    <h3 className='mb-2.5 psbd text-base/6 text-white'>Tech</h3>
                                    <li><a onClick={() => redirectToSection('hero-section')} style={{ cursor: 'pointer' }}><span className="text-sm/7 pr text-[#A9C5E4] ">Overview</span></a></li>
                                    <li><a onClick={() => redirectToSection('character-section')} style={{ cursor: 'pointer' }}><span className="text-sm/7 pr text-[#A9C5E4] ">Characters</span></a></li>
                                    <li><a onClick={() => redirectToSection('roadmap-section')} style={{ cursor: 'pointer' }}><span className="text-sm/7 pr text-[#A9C5E4] ">Roadmap</span></a></li>
                                    <li><a href='/Blog' onClick={() => handleExternalLinkClick('Blog')}><span className="text-sm/7 pr text-[#A9C5E4] ">Blogs</span></a></li>
                                </ul>
                            </div>
                            <div>
                                <ul>
                                    <h3 className='mb-2.5 psbd text-base/6 text-white'>Pages</h3>
                                    <li><a href='/Blog' onClick={() => handleExternalLinkClick('Blog')}><span className="text-sm/7 pr text-[#A9C5E4] ">Blog</span></a></li>
                                    <li><a onClick={() => redirectToSection('hero-section')} style={{ cursor: 'pointer' }}><span className="text-sm/7 pr text-[#A9C5E4] ">About Us</span></a></li>
                                    <li> <a href="https://linktr.ee/ArmaBlock" target="_blank"><span className="text-sm/7 pr text-[#A9C5E4] " onClick={() => handleExternalLinkClick('linktr')}>Community</span></a></li>
                                </ul>
                            </div>
                            <div>
                                <ul>
                                    <h3 className='mb-2.5 psbd text-base/6 text-white'>Resources</h3>
                                    <li><a href='/Tokenomics' onClick={() => handleExternalLinkClick('Tokenomics')}><span className="text-sm/7 pr text-[#A9C5E4] ">Token</span></a></li>
                                    <li><a href='https://drive.google.com/file/d/1Hg7cfnlgG_4O78bSPT1RKIYBrYwR54ub/view?usp=sharing' target='blank' onClick={() => handleExternalLinkClick('Litepaper')}><span className="text-sm/7 pr text-[#A9C5E4] ">Litepaper</span></a></li>
                                    {presaleStatus && <li><span href='/Pre-sale' onClick={() => handleExternalLinkClick('Pre-sale')} className="text-sm/7 pr text-[#A9C5E4] ">Pre-sale</span></li>}
                                    <li><a href='/Tokenomics' onClick={() => handleExternalLinkClick('Tokenomics')}><span className="text-sm/7 pr text-[#A9C5E4] ">Tokenomics</span></a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="mt-16 flex flex-row flex-wrap justify-center items-center gap-1.5">
                            <a href="https://twitter.com/arma_block" target="_blank" onClick={() => handleSocialMediaClick('twitter')}>
                                <div className="w-[41px] h-[41px] rounded-full bg-[#231F33] flex items-center justify-center">
                                    <img src={TwitterIcon} alt="twitter" className='w-4 h-4 object-contain' />
                                </div>
                            </a>
                            <a href="https://t.me/armablock" target="_blank" onClick={() => handleSocialMediaClick('telegram')}>
                                <div className="w-[41px] h-[41px] rounded-full bg-[#231F33] flex items-center justify-center">
                                    <img src={TelegramIcon} alt="telegram" className='w-4 h-4 object-contain' />
                                </div>
                            </a>
                            <a href="https://t.me/armablockchat" target="_blank" onClick={() => handleSocialMediaClick('telegram-community')}>
                                <div className="w-[41px] h-[41px] rounded-full bg-[#231F33] flex items-center justify-center">
                                    <img src={TelegramCommunityIcon} alt="telegram-community" className='w-4 h-4 object-contain' />
                                </div>
                            </a>
                            <a href="https://discord.com/invite/V3EQBsFXEK" target="_blank" onClick={() => handleSocialMediaClick('discord')}>
                                <div className="w-[41px] h-[41px] rounded-full bg-[#231F33] flex items-center justify-center">
                                    <img src={DiscordIcon} alt="discord" className='w-4 h-4 object-contain' />
                                </div>
                            </a>
                            <a href="https://www.instagram.com/arma_block/" target="_blank" onClick={() => handleSocialMediaClick('instagram')}>
                                <div className="w-[41px] h-[41px] rounded-full bg-[#231F33] flex items-center justify-center">
                                    <img src={InstagramIcon} alt="instagram" className='w-4 h-4 object-contain' />
                                </div>
                            </a>
                            {/* <a href="https://www.facebook.com/profile.php?id=61557821586012" target="_blank" onClick={() => handleSocialMediaClick('medium')}>
                                <div className="w-[41px] h-[41px] rounded-full bg-[#231F33] flex items-center justify-center">
                                    <img src={FacebookIcon} alt="facebook" className='w-4 h-4 object-contain' />
                                </div>
                            </a> */}
                            <a href="https://medium.com/@Arma_Block" target="_blank" onClick={() => handleSocialMediaClick('medium')}>
                                <div className="w-[41px] h-[41px] rounded-full bg-[#231F33] flex items-center justify-center">
                                    <img src={MediumIcon} alt="medium" className='w-4 h-4 object-contain' />
                                </div>
                            </a>
                            {/* <a href="#">
                                <div className="w-[41px] h-[41px] rounded-full bg-[#231F33] flex items-center justify-center">
                                    <img src={YoutubeIcon} alt="" className='w-4 h-4 object-contain' />
                                </div>
                            </a> */}
                            <a href="https://etherscan.io/token/0x84fAD63F8f26335f4F1BebC9fbF5ba277Fd23C9E#code" target="_blank" onClick={() => handleSocialMediaClick('etherscan')}>
                                <div className="w-[41px] h-[41px] rounded-full bg-[#231F33] flex items-center justify-center">
                                    <img src={EhterscanIcon} alt="etherscan" className='w-4 h-4 object-contain' />
                                </div>
                            </a>
                            <a href="https://linktr.ee/ArmaBlock" target="_blank" onClick={() => handleSocialMediaClick('linktr')}>
                                <div className="w-[41px] h-[41px] rounded-full bg-[#231F33] flex items-center justify-center">
                                    <img src={LinktreeIcon} alt="linktr" className='w-4 h-4 object-contain' />
                                </div>
                            </a>
                            <a href="mailto:info@armablock.io" target="_blank" onClick={() => handleSocialMediaClick('mail')}>
                                <div className="w-[41px] h-[41px] rounded-full bg-[#231F33] flex items-center justify-center">
                                    <img src={MailIcon} alt="mail" className='w-4 h-4 object-contain' />
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className='lg:w-1/4 w-full  flex justify-center md:justify-center'>
                        <div className='flex flex-col'>
                            <h3 className='text-base/6 text-white md:text-start text-center psbd mb-2.5'>Contact Us</h3>
                            <a href="mailto:info@armablock.io" target="_blank" onClick={() => handleSocialMediaClick('mail')} > <p className='text-base/6 pmed text-trans md:text-start text-center text-grad6'>info@armablock.io</p></a>
                            <div className="grid grid-cols-2 mt-[29px] gap-1">
                                {/* <div className='bg-[#20222A] w-max rounded-lg p-4 flex flex-row justify-center gap-2 items-center h-[38px]'>
                                    <img src={solidProoSP} alt="" className='w-[18px] h-5' />
                                    <div className=''>
                                        <p className='pr text-xs text-white mb-[0.5px]'>SOLID Proof</p>
                                        <p className='text-white text-[9px] pr mb-0'>Audited by</p>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex flex-wrap md:flex-row flex-col lg:gap-0 gap-5 items-center justify-center pb-8 mt-8  md:mx-14">
                    <h3 className='text-[#A9C5E4] text-base pr md:mr-12 text-center md:text-start'>© 2024 Arma Battle Ground. All Rights Reserved.</h3>
                    <a href="/PrivacyPolicy" className='text-[#A9C5E4] text-base pr' onClick={() => handleLinkClick('Privacy Policy')}>
                        Privacy Policy
                    </a>
                    <div className='mx-3 bg-[#424B54] h-3 w-[1px] md:block hidden' onClick={() => handleLinkClick('Terms and Conditions')}></div>
                    <a href="/Tnc" className='text-[#A9C5E4] text-base pr'>
                        Terms and Conditions
                    </a>
                    <div className='mx-3 bg-[#424B54] h-3 w-[1px] md:block hidden' onClick={() => handleLinkClick('Cookie Policy')}></div>
                    <a href="/Cookie" className='text-[#A9C5E4] text-base pr'>
                        Cookie Policy
                    </a>
                </div>
            </div>
            {showDialog && (
                <div className="dialog-overlay">
                    <div className="dialog flex justify-between flex-col">
                        <p>Thank you for subscribing to our newsletter!</p>
                        <div className='flex justify-end'>
                            <button onClick={handleCloseDialog}>OK</button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default Footer