import React from 'react';
import PropTypes from 'prop-types';
import { kucoin } from '../services/image';

function ReviewCard({ reviewText, authorName, authorTitle, imageSrc, currentIndex }) {
    return (
        <div>
            <div className=" px-3 review_section relative lg:w-1/2 md:w-11/12 w-11/12 mx-auto">
                <p className=' text-xl md:text-2xl/8 pr text-white text-start lg:line-clamp-6 md:line-clamp-4 sm:line-clamp-5 line-clamp-5 xl:min-h-[200px] lg:min-h=[260px] base:min-h-[200px] min-h-[310px]'>
                    {reviewText[currentIndex]}</p>
            </div>
            <div className='px-3 flex md:mt-[50px] mt-5 flex-row justify-center md:justify-start items-center gap-[29px] lg:w-1/2 md:w-11/12 w-full mx-auto'>
                {/* <div className='w-[88px] h-[88px] overflow-hidden rounded-full border_people '>
                    <img src={imageSrc} alt="" />
                </div> */}
                <div >
                    <p className='text-grad5 text-trans text-2xl/8 pm mb-0 text-start'>{authorName[currentIndex]}</p>
                    <h5 className='text-white text-base/8 pr text-start'>
                        {authorTitle[currentIndex]}
                    </h5>
                </div>
            </div>
        </div>
    )
}
ReviewCard.propTypes = {
    reviewText: PropTypes.arrayOf(PropTypes.string).isRequired,
    authorName: PropTypes.arrayOf(PropTypes.string).isRequired,
    authorTitle: PropTypes.arrayOf(PropTypes.string).isRequired,
    currentIndex: PropTypes.number.isRequired,
};

export default ReviewCard