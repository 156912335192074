import React, { memo, useEffect, useRef, useState } from 'react'
import Header from '../component/Header'
import Banner from '../component/Banner'
import { SkylinePenthouse, Unrea, TrustWallet, Peragon, Metamak, Ledger, Hostinges, Freepic, Exodus, Etherium, Coinbase, BNB, AmazonAWS, Blender, SubterraLab, IroncladBastion, NeonStreets, SectorZero, MapName1, MapName2, MapName3, MapName4, MapName5, siteLogo, LoadingAnimation, Map1, Map2, Map3, Map4, Map5, ToxicViper, ArcticShatter, LethalLava, ShockwaveBlitz, RusticRaptor, GoldenGrenade, ObsidianOrb, CrimsonCarnage, StormSurge, BlazeBreaker, FrostbiteFang, ShadowSerpent, MidnightOnyx, InfernoBlaze, ElectricBlue, DesertCamo, BloodRedFury, ArcticFrost, charactor1, maps, appmeme, appmusic, appqr, appstable, apptrand, chemist, postervideo, playbtnsvg, api, gsr, kucoin, aplogo, coinin, microsoft, altcoinbuzz, benzinga, smart, insider, cryptomonde, Mapt1, Mapt2 } from '../services/image'
import TopHeading from '../component/TopHeading'
import GradientText from '../component/GradientText'
import MarketPlaceSwiper from '../component/MarketPlaceSwiper'
import Footer from '../component/Footer'
import Swiper from 'swiper/bundle';
import 'swiper/css/bundle';
import BlogCard from '../component/BlogCard'
import ApplicationCard from '../component/ApplicationCard'
import ImageCard from '../component/ImageCard'
import ReviewCard from '../component/ReviewCard'
import { WOW } from 'wowjs'
import 'animate.css';
import { useGSAP } from "@gsap/react";
import { gsap } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { NavLink, useLocation } from 'react-router-dom'
import StaticRoadmap from '../component/StaticRoadmap'
import MarketPlaceCard from '../component/MarketPlaceCard'
import MarketPlaceSkeletonLoader from '../component/MarketPlaceSkeletonLoader'
import MobileSizeRoadmap from '../component/MobileSizeRoadmap'
import blogData from '../component/BlogData';
import VideoCard from '../component/VideoCard'
import MapImage from '../component/MapImage'
import { baseUrl } from '../env'
import Loader from '../component/Loader'
import Lottie from 'react-lottie';
import LottieLoader from '../component/LottieLoader';
import Partical from './Partical';
import logEvent from '../analyticsUtils';
import { callPreSaleApi } from '../component/GetSection';

// import lottie from 'lottie-web'; 

let tex = [1, 2, 3, 4, 5];
let text = [1, 2, 3, 4, 5, 6];
// let text2 = [1, 2, 3, 4, 5, 6, 7, 5, 3, 2, 3, 4, 5, 6, 7, 5,]
const ArFilterColor = ["#7a8ba1", "#ddc9c9", "#a8c8d1", "#e57c02", "#67dcdf", "#dc1415"]
const shotFilterColor = ["#3a5369", "#c75632", "#6fb4ca", "#d47858", "#325b71", "#fd69bf"]
const greanadeFilterColor = ["#c88f5c", "#81743a", "#357993", "#1b9823", "#c7bb0e", "#5b5a5a"]
const ArImg = [MidnightOnyx, DesertCamo, ArcticFrost, InfernoBlaze, ElectricBlue, BloodRedFury]
const Ar = ["Midnight Onyx", "Desert Camo", "Arctic Frost", "Inferno Blaze", "Electric Blue", "Blood Red Fury"]
const mapImg = [Map1, Map2, Map3, Map4, Map5, ""]
const mapTitle = ["Sector Zero", "Ironclad Bastion", "Skyline Penthouse (Locked)", "Subterra Lab (Locked)", "Neon Streets (Locked)", ""]
const shotImg = [ShadowSerpent, RusticRaptor, FrostbiteFang, BlazeBreaker, StormSurge, CrimsonCarnage]
const shot = ["Shadow Serpent", "Rustic Raptor", "Frostbite Fang", "Blaze Breaker", "Storm Surge", "Crimson Carnage"]
const greanadeImg = [LethalLava, GoldenGrenade, ArcticShatter, ToxicViper, ShockwaveBlitz, ObsidianOrb]
const greanade = ["Lethal Lava", "Golden Grenade", "Arctic Shatter", "Toxic Viper", "Shockwave Blitz", "Obsidian Orb"]

function JumpadCard(props) {
    const [flipped, setFlipped] = useState(false);

    return (
        <div
            onMouseEnter={() => setFlipped(true)}
            onMouseLeave={() => setFlipped(false)}
            className={`jumpads-cards w-[143px] h-[194px] flex flex-col gap-3 items-center wow animate__animated animate__fadeInUp`}
            style={{ animationDelay: props.delay, '--filter-color': props.filterColor }}
        >
            <div className={`card-wrapper ${flipped ? 'flipped' : ''}`}>
                <div className='card-wrapper-front'>
                    <div className='lg:w-[90px] w-[90px] h-[90px] lg:h-[100px] flex items-center justify-center'>
                        <img src={props.image} alt="" />
                    </div>
                    <p className='md:text-base text-xs text-center pbd text-white'>{props.name}</p>
                    <div className="absolute -bottom-4 left-3 h-[26px]  px-2  rounded-full bg-[#563D3C]">
                        <span className='text-sm text-center pr text-grad4 text-trans text-nowrap'>Available soon</span>
                    </div>
                </div>
            </div>
        </div>
    );
}

const Home = memo((props) => {

    const { pathname } = useLocation();
    const [jumpadsData, setJumpadsData] = useState(null);
    const [armorsData, setArmorsData] = useState(null);
    const [skinData, setSkinData] = useState(null);
    const [gunSkinData, setGunSkinData] = useState(null);
    const [grenadeSkinsData, setGrenadeSkinsData] = useState(null);
    const homebannervideo = useRef(null)
    const [charactorCardData, setCharactorCardData] = useState(null)
    const [isLoading, setIsLoading] = useState(true);
    const [color, setColor] = useState('transparent');
    const [selectedid, setSelectedid] = useState(0);
    const [currentIndexReviewCard, setCurrentIndexReviewCard] = useState(0);
    const [loadingMap, setLoadingMap] = useState(true);
    const swiperRef = useRef(null);
    const [isVideoLoaded, setIsVideoLoaded] = useState(false);
    const location = useLocation();
    const text2 = [
        { image: AmazonAWS },
        { image: Blender },
        { image: BNB },
        { image: Coinbase },
        { image: Etherium },
        { image: Exodus },
        { image: Freepic },
        { image: Hostinges },
        { image: Ledger },
        { image: Metamak },
        { image: Peragon },
        { image: TrustWallet },
        { image: Unrea },
    ];

    useEffect(() => {
        callPreSaleApi();
        logEvent('Home_page_view', {
            page_path: location.pathname,
            content_type: 'page',
            content_event: 'view'
        });
    }, []);

    useEffect(() => {
        const imagesToLoad = [
            MapName3, SectorZero, SectorZero,
            MapName5, SubterraLab, SubterraLab,
            MapName2, NeonStreets, NeonStreets,
            MapName1, IroncladBastion, IroncladBastion
        ];

        Promise.all(imagesToLoad.map(imageUrl => {
            return new Promise((resolve, reject) => {
                const img = new Image();
                img.onload = () => resolve();
                img.onerror = () => reject(new Error(`Failed to load image: ${imageUrl}`));
                img.src = imageUrl;
            });
        })).then(() => {
            setLoadingMap(false); // All images are loaded
        }).catch(error => {
            console.error(error);
            setLoadingMap(false); // Set loading to false even if there's an error loading images
        });
    }, []);

    const handleMouseDown = (id) => {
        setColor('#AC725E');
        setSelectedid(id)
    };
    const handleMouseUp = (id) => {
        setColor('transparent');
        setSelectedid(id)
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    useEffect(() => {
        const swiper = new Swiper('.partnerSwiper', {

            loop: true,
            slidesPerView: 'auto',
            centeredSlides: true,
            autoplay: {
                delay: 5000,
            },
            // navigation: {
            //     nextEl: '.swiper-button-next',
            //     prevEl: '.swiper-button-prev',
            // },
            // pagination: {
            //     el: '.swiper-pagination',
            // },
            spaceBetween: 20,
            breakpoints: {
                300: {
                    slidesPerView: 2,
                    spaceBetween: 20
                },
                780: {
                    slidesPerView: 3,
                    spaceBetween: 20
                },
                1140: {
                    slidesPerView: 5,
                    spaceBetween: 40
                }
            },

        });

    }, [text2]);

    const reviewText = [
        "Digital transformation has evolved from basic process digitisation, like early e-banking, to embracing avant-garde technologies such as AI, AR, VR, ML, and Blockchain. This industry shift signifies a steadfast commitment to leading technological advancements, revolutionising the traditional mainframe-centric model.",
        "The future of gaming will be one led by web3 technology adoption and one that empowers players to be active participants in their products",
        "Blockchain is the most effective form of copy protection and digital certification the world has ever seen, and the technology is quietly helping companies increase efficiency, transparency, and optimize costs.",
        "It doesn’t need to be the front-and-center selling point. It’s still about gameplay. It’s still about that experience. It’s still about getting lost in those really interesting creative worlds and enc,ounters. And the technology element of that, like blockchain, just facilitates that in a meaningful way.",
        "It presents a clean slate for gamers and proves to naysayers that esports  is, in fact , a serious sport that deserves to be recognised for its skilled and hard-working players who will be competing against some of the world's best players and put India on the map of professional gaming countries.",
    ];

    const authorName = [
        "Mohua Sengupta",
        "Igor Tanzil",
        "Mrinal Manohar",
        "Ben James",
        "Animesh Agrawal",
    ];

    const authorTitle = [
        "CEO of MGN",
        "Co-Founder Of Mythic Protocol",
        "CEO of Casper Labs",
        "CEO of Atlas",
        "Founder and CEO of 8bit Creatives",
    ];

    useEffect(() => {
        const swiper = new Swiper('.reviewSwiper', {
            loop: true,
            pagination: {
                el: '.swiper-pagination',
            },
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            slidesPerView: 1, // Adjust this value as needed
            slidesPerGroup: 1, // Adjust this value as needed
        });

        swiper.on('slideChange', () => {
            // console.log('Slide changed:', swiper.realIndex);
            setCurrentIndexReviewCard(swiper.realIndex % reviewText.length);
        });
    }, []);

    const handleNextReviewCard = () => {
        setCurrentIndexReviewCard((prevIndex) => (prevIndex + 1) % reviewText.length);
    };

    const handlePrevReviewCard = () => {
        setCurrentIndexReviewCard((prevIndex) => (prevIndex - 1 + reviewText.length) % reviewText.length);
    };

    useEffect(() => {
        callAnimationOn()
    }, []);

    function callAnimationOn() {
        const wow = new WOW(
            {
                boxClass: 'wow',      // default
                animateClass: 'animated', // default
                offset: 0,          // default
                mobile: true,       // default
                live: false        // default
            }
        )
        wow.init();
    }
    useEffect(() => {
        getCharactorData();
        // getJumppadsCardData();
        // getArmorsCardData();
        // getSkinsCardData();
        // getGunSkinsCardData();
        // getGrenadeSkinsData();
    }, []);

    const jumpads = [
        {
            id: 1,
            image: appstable,
            name: 'Guardian Plate',
            filterColor: '#4485a6'
        },
        {
            id: 2,
            image: appmeme,
            name: 'Sentinel Shell',
            filterColor: '#fd4e32'
        },
        {
            id: 3,
            image: appqr,
            name: 'Titan Mail',
            filterColor: '#cccccc'
        },
        {
            id: 4,
            image: appmusic,
            name: 'Colossus Cuirass',
            filterColor: '#ba5bbc'
        },
        {
            id: 5,
            image: apptrand,
            name: 'Citadel Armor',
            filterColor: 'rgba(212, 666, 561, 0.5)'
        },

        {
            id: 6,
            image: chemist,
            name: 'Sovereign Shield',
            filterColor: '#83949e'
        },
    ]

    const getSkinsCardData = async () => {
        await fetch(`${baseUrl}/get-all-ar-skis`,
            {
                method: 'POST',
            })
            .then(async (resp) => {
                const resp2 = await resp.json();
                console.log('resprespresp', resp2)
                setSkinData(resp2.data)
            })
            .catch((err) => {
                console.log('eerr', err)
            })
    }

    const getGunSkinsCardData = async () => {
        await fetch(`${baseUrl}/get-all-shot-gn-skins`,
            {
                method: 'POST',
            })
            .then(async (resp) => {
                const resp2 = await resp.json();
                console.log('resprespresp', resp2)
                setGrenadeSkinsData(resp2.data)
            })
            .catch((err) => {
                console.log('eerr', err)
            })
    }

    const getGrenadeSkinsData = async () => {
        await fetch(`${baseUrl}/get-all-shot-gn-skins`,
            {
                method: 'POST',
            })
            .then(async (resp) => {
                const resp2 = await resp.json();
                console.log('resprespresp', resp2)
                setGunSkinData(resp2.data)
            })
            .catch((err) => {
                console.log('eerr', err)
            })
    }

    const getCharactorData = async () => {
        await fetch(`${baseUrl}/get-all-characters`, {
            method: 'POST',
        })
            .then(async (resp) => {
                const resp2 = await resp.json();
                // console.log('resprespresp', resp2);
                setCharactorCardData(resp2.data);
                setIsLoading(false);
                // wow.sync();
            })
            .catch((err) => {
                console.log('eerr', err);
                setIsLoading(false);
            })
    }

    useEffect(() => {
        const videoElement = homebannervideo.current;
        // console.log('Video element:', videoElement);

        if (!videoElement) {
            // console.log('Video element not found.');
            return;
        }

        const handleMetadataLoaded = () => {
            // console.log('Metadata loaded.');
            // Once metadata is loaded, wait for at least 1 second to consider the video as played
            setTimeout(() => {
                setIsVideoLoaded(true);
            }, 3000); // Adjust the timeout value as needed
        };

        // Add event listener for metadata loaded
        videoElement.addEventListener('loadedmetadata', handleMetadataLoaded);

        // Clean up
        return () => {
            videoElement.removeEventListener('loadedmetadata', handleMetadataLoaded);
        };
    }, []);

    useEffect(() => {
        callPreSaleApi();
        // Function to set isLoading to false with a delay
        const setIsLoadingWithDelay = () => {
            setTimeout(() => {
                setIsLoading(false);
            }, 3000);
        };

        // Check if isLoading is true and then set it to false with a delay
        if (!isLoading) {
            setIsLoadingWithDelay();
        }
    }, [isLoading]);

    const [isPlaying, setIsPlaying] = useState(false);
    const videoRef = useRef(null);

    const handleVideoToggle = () => {
        const video = videoRef.current;
        if (!video) return;

        if (video.paused || video.ended) {
            video.play();
            setIsPlaying(true);
        } else {
            video.pause();
            setIsPlaying(false);
        }
        logEvent(isPlaying ? 'video_pause' : 'video_play', {
            content_type: 'video',
            content_event: isPlaying ? 'pause' : 'play'
        });
    };

    const handleVideoClick = () => {
        const video = videoRef.current;
        if (!video) return;

        if (isPlaying) {
            video.pause();
            setIsPlaying(false);
        }
    };

    const handleButtonClick = () => {
        // Log event when the button is clicked
        logEvent('show_all_blogs_button_click', {
            content_type: 'button',
            content_event: 'click'
        });
    };

    return (
        <>
            {isLoading && !isVideoLoaded ? (
                <div className="loader" style={{
                    background: '#000000',
                    height: '100vh',
                    display: 'flex', justifyContent: 'center',
                }}>
                    {/* Your animation will be rendered here */}
                    <div className='loadingLogo z-10'>
                        <img src={siteLogo} />
                        <div className='hidden'>
                            <video id="homeherovideo" ref={homebannervideo} autoPlay={true} muted loop className="absolute z-[1] object-cover w-full h-full  top-0 left-0"  >
                                <source src='https://armanew.s3.amazonaws.com/arma_media/b45d48ab-4361-4132-9fc2-b185bb6294a5.mp4' type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                        </div>
                    </div>
                    <Lottie
                        options={{
                            loop: true,
                            autoplay: true,
                            animationData: LoadingAnimation
                        }}
                        width={700}
                        height={'100vh'}
                    />
                </div>
            ) : (
                <>
                    <Partical />
                    <div className="relative overflow-hidden h-screen">
                        <video id="homeherovideo" ref={homebannervideo} autoPlay={true} muted loop className="absolute z-[1] object-cover w-full h-full  top-0 left-0"  >
                            <source src='https://armanew.s3.amazonaws.com/arma_media/b45d48ab-4361-4132-9fc2-b185bb6294a5.mp4' type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                        <div className="absolute inset-0 bg-black opacity-40 z-[2]"></div>
                        <div className='lg:mx-[59px] mx-4 mb-10 md:mb-0 relative z-10'>
                            <Header />
                            <Banner />
                        </div>
                    </div>
                    <div className="marketplace-wrapper" id="character-section" >
                        <div className="bg-[#0b0c17] " >
                            <div className="lg:mx-[59px] mx-4" >
                                <div className="xl:pt-28 lg:pt-16 py-10"  >
                                    {/* <TopHeading text={''} /> */}
                                    <div className='xl:w-10/12  mx-auto'>
                                        <GradientText props={`Characters`} />
                                    </div>
                                    <div className='z-10 relative'>
                                        {isLoading ? (
                                            // Show skeleton loader while loading
                                            Array.from({ length: 4 }).map((_, index) => (
                                                <div className="" key={index}>
                                                    <MarketPlaceSkeletonLoader />
                                                </div>
                                            ))
                                        ) : (
                                            <div className="charactorSlider" >
                                                {
                                                    charactorCardData &&
                                                    charactorCardData.map((item, idx) => {
                                                        return (
                                                            <div className={''} key={idx}>
                                                                <MarketPlaceCard item={item} idx={idx} />
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className=''>
                        <MarketPlaceSwiper />
                    </div>
                    <div className='Arma_core_elements'>
                        <div className="lg:mx-[59px] mx-4">
                            <div className="lg:pt-10 pt-2 wow animate__animated animate__fadeInUp">
                                <TopHeading text={'Terrain Tactics: Choose Your Ground'} />
                                <div className='lg:w-3/4 w-full mx-auto'>
                                    <GradientText props={`Game Maps`} />
                                </div>
                            </div>
                        </div>
                        <div className="lg:mx-[49px] mx-4 mt-[73px]">
                            {/* <div className="flex flex-row flex-wrap gap-9 justify-center">
                        {tex.map((items, idx) => {
                            const delay = idx * 100 + "ms";

                            return (
                                <div key={idx} className='h-[300px] sm:h-[350px] md:h-[400px] lg:h-[495px] sm:w-[45%] lg:w-[31%] relative group items  rounded-[30px] overflow-hidden wow animate__animated  animate__slideInUp' style={{ animationDelay: delay }}>
                                    <img src={mapImg[idx]} alt="" className='object-cover h-full' />
                                    <div className="h-full w-full absolute p-[22px] gradientover_element z-1 translate-y-0 group-hover:-translate-y-full transition-transform duration-500 ease-in-out">
                                        <div className="h-full flex align-center justify-end flex-col">
                                            <h3 className='text-[32px]/[48px] pm text-white'>{mapTitle[idx]}</h3>
                                            <p className='mb-0 mt-2.5 text-base/6 text-white pr'>

                                            </p>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div> */}
                            <div className="flex flex-row flex-wrap gap-9 justify-center max-w-[1485px] mx-auto">
                                <>{loadingMap ? (
                                    <LottieLoader /> // Show loader animation while images are loading
                                ) : (
                                    <>
                                        <MapImage image1={MapName3} image2={SectorZero} image3={SectorZero} />
                                        <MapImage image1={MapName5} image2={SubterraLab} image3={SubterraLab} />
                                        <MapImage image1={MapName2} image2={NeonStreets} image3={NeonStreets} />
                                        <MapImage image1={MapName1} image2={IroncladBastion} image3={IroncladBastion} />
                                        <MapImage image1={MapName4} image2={SkylinePenthouse} image3={SkylinePenthouse} />
                                    </>
                                )}
                                </>
                            </div>
                        </div>
                    </div>
                    <div className='app
            lications-wrapper'>
                        <div className="lg:mx-[59px] mx-4">
                            <div className="pt-10">
                                <TopHeading text={'Dress to Kill: Equipment That Speaks Volumes'} />
                                <div className='lg:w-[85%] mx-auto'>
                                    <GradientText props={`Equip. Enhance. Express. Elevate your game with exclusive gear and skins that make a statement on the battlefield.`} />
                                </div>
                                <p className='text-grad3 text-center text-[30px] pm mb-[30px] mt-10'>
                                    Armors
                                </p>
                                <div className="grid lg:grid-cols-6 md:grid-cols-3 grid-cols-2  gap-5 mx-auto w-full items-center justify-items-center xl:w-[78%] justify-center">
                                    {jumpads.map((item, idx) => (
                                        <JumpadCard
                                            key={idx}
                                            name={item.name}
                                            image={item.image}
                                            delay={idx * 100 + "ms"}
                                            filterColor={item.filterColor}
                                        />
                                    ))}
                                </div>

                                <p className='text-grad3 text-center text-[30px] pm mb-[30px] mt-[50px]'>
                                    AR Skins
                                </p>
                                <div className="grid lg:grid-cols-6 md:grid-cols-3 grid-cols-2  gap-5 mx-auto w-full items-center justify-items-center xl:w-[78%] justify-center">
                                    {text.map((items, idx) => {
                                        const delay = idx * 100 + "ms";
                                        return (
                                            <ApplicationCard text={Ar[idx]} img={ArImg[idx]} delay={delay} filterColor={ArFilterColor[idx]} />
                                        )
                                    })}
                                </div>

                                <p className='text-grad3 text-center text-[30px] pm mb-[30px] mt-[50px]'>
                                    Shotgun Skins
                                </p>
                                <div className="grid lg:grid-cols-6 md:grid-cols-3 grid-cols-2  gap-5 mx-auto w-full items-center justify-items-center xl:w-[78%] justify-center">
                                    {text.map((items, idx) => {
                                        const delay = idx * 100 + "ms";
                                        return (
                                            <ApplicationCard text={shot[idx]} img={shotImg[idx]} delay={delay} filterColor={shotFilterColor[idx]} />
                                        )
                                    })}

                                </div>
                                <p className='text-grad3 text-center text-[30px] pm mb-[30px] mt-[50px]'>
                                    Grenade Skins
                                </p>
                                <div className="grid lg:grid-cols-6 md:grid-cols-3 grid-cols-2  gap-5 mx-auto w-full items-center justify-items-center xl:w-[78%] justify-center">
                                    {text.map((items, idx) => {
                                        const delay = idx * 100 + "ms";
                                        return (
                                            <ApplicationCard text={greanade[idx]} img={greanadeImg[idx]} delay={delay} filterColor={greanadeFilterColor[idx]} />
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='product_roadmap_wrapper hidden md:block' id='roadmap-section'>
                        {charactorCardData && <StaticRoadmap />}
                    </div>
                    <div className='product_roadmap_wrapper md:hidden block' id='roadmap-section'>
                        <MobileSizeRoadmap />
                    </div>
                    <div className='among_poineer_wrapper'>
                        <div className="lg:mx-[59px] mx-4">
                            <div className="pt-10">
                                <TopHeading text={'Showcase of Champions: Epic Gameplay Unleashed'} />
                                <div className='lg:w-1/3 md:w-1/2 w-full mx-auto'>
                                    <GradientText props={`Legends in Action`} />
                                </div>
                                <div className="flex lg:flex-row flex-col items-center gap-[22px] mt-[66px]">
                                    <div className='lg:w-1/2 w-full'>
                                        <div className=' w-full rounded-[30px] overflow-hidden  lg:h-[420px] md:h-[350px] sm:h-[300px] relative wow animate__animated animate__fadeInUp' >
                                            <video
                                                ref={videoRef}
                                                src={require('../assets/1.mp4')}
                                                // controls
                                                // poster={postervideo}
                                                className='object-fill h-full w-full'
                                                width={'100%'}
                                                height={420}
                                                onClick={handleVideoClick}
                                            ></video>
                                            {!isPlaying && (
                                                <button
                                                    className='bg-[#09090F] rounded-full md:h-[85px] md:w-[85px] h-[50px] w-[50px] absolute top-[42%] flex items-center justify-center left-[42%] homeVideoPlayBtn'
                                                    onClick={handleVideoToggle}
                                                >
                                                    <div className='md:w-6 md:h-6  h-3 w-3'>
                                                        <img src={playbtnsvg} alt="" />
                                                    </div>
                                                </button>
                                            )}
                                        </div>
                                    </div>
                                    <div className='flex-1 flex flex-row content-center justify-center gap-6 flex-wrap'>
                                        <div className='h-[200px] lg:w-[45%] md:w-[48%] w-full  rounded-[30px] overflow-hidden relative wow animate__animated animate__fadeInUp ' style={{ animationDelay: 500 }}>
                                            <VideoCard url={require('../assets/2.mp4')} />
                                        </div>
                                        <div className='h-[200px] lg:w-[45%] md:w-[48%] w-full  rounded-[30px] overflow-hidden relative wow animate__animated animate__fadeInUp ' style={{ animationDelay: 700 }}>
                                            <VideoCard url={require('../assets/3.mp4')} />
                                        </div>
                                        <div className='h-[200px] lg:w-[45%] md:w-[48%] w-full  rounded-[30px] overflow-hidden relative wow animate__animated animate__fadeInUp ' style={{ animationDelay: 900 }}>
                                            <VideoCard url={require('../assets/4.mp4')} />
                                        </div>
                                        <div className='h-[200px] lg:w-[45%] md:w-[48%] w-full  rounded-[30px] overflow-hidden relative wow animate__animated animate__fadeInUp ' style={{ animationDelay: 1100 }}>
                                            <VideoCard url={require('../assets/5.mp4')} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* <div className='strong_backing_wrapper '>
                        <div className="lg:mx-[59px] mx-4">
                            <div className="lg:pt-[50px] md:pt-10 sm:pt-5 pt-3 wow animate__animated animate__fadeInUp">
                                <TopHeading text={'Strong backing'} />
                                <div className='lg:w-1/2 md:w-11/12 w-full mx-auto'>
                                    <GradientText props={`Trusted and validated by the
                                biggest names in the field`} />
                                </div>
                                <div className="grid lg:grid-cols-5 md:grid-cols-3 sm:grid-cols-2 grid-cols-2 gap-4 mt-16">
                                    {text.map((items, idx) => {
                                        const delay = idx * 100 + "ms";
                                        return (
                                            <div className='wow animate__animated animate__fadeInUp' style={{ animationDelay: delay, backgroundColor: idx === selectedid ? color : "transparent", borderRadius: 8 }} key={idx}
                                                onMouseDown={() => handleMouseDown(idx)}
                                                onMouseUp={() => handleMouseUp(idx)}>
                                                <ImageCard image={gsr} />
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    </div> */}
                    <div className=''>
                        <div className="lg:mx-[59px] mx-4">
                            <div className="lg:pt-[50px] md:pt-10 sm:pt-5 pt-3  wow animate__animated animate__fadeInUp">
                                <TopHeading text={'Our Esteemed Partners'} />
                                <div className='lg:w-[45%] md:w-3/5 sm:w-11/12 w-full mx-auto'>
                                    <GradientText props={`Powering "Arma: The Battle Ground" Together`} />
                                </div>
                            </div>
                            <div className='md:h-[200px] h-[200px]'>
                                <div className="swiper partnerSwiper md:!py-10 !pb-[13rem] lg:pb-10 h-[200px] lg:h-[250px]  mt-9 md:mt-14 wow animate__animated animate__fadeInUp">
                                    <div className="swiper-wrapper ">
                                        {text2.map((item, idx) => {
                                            return (
                                                <div className="swiper-slide" key={idx}>
                                                    <div className='h-[50px] md:h-[99px] p-10 lg:p-5 rounded-lg flex items-center justify-center border-[#FDE0DA] border border-solid'>
                                                        <img src={item.image} alt={`Image ${idx}`} className='w-[100px] h-auto object-contain' />
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                    <div className="swiper-pagination !hidden"></div>
                                    {/* <div className="swiper-button-prev md:!w-[60px] md:!h-[60px] md:!left-[40%] !top-[90%] md:!top-[77%] PREV rounded-md"></div>
                            <div className="swiper-button-next NEXT !top-[90%] md:!w-[60px] md:!h-[60px] md:!right-[40%] md:!top-[77%]  rounded-md"></div> */}
                                </div>
                            </div>
                            {/* <div className='text-center lg:mt-20 mt-8 mx-auto w-full flex justify-center'>
                        <button className='clip game_download rounded-lg flex gap-1.5 items-center justify-center h-[61px] px-5  rounded-br-[24px]'>
                            <span className='text-base text-white text-center pbd tracking-wide'>Become a Partner</span>
                        </button>
                    </div> */}
                        </div>
                    </div>
                    <div className='review_section_wrapper '>
                        <div className='lg:mt-[131px] mt-24 mb-20'>
                            <div className="h-[400px]">
                                <div className="swiper !pb-[120px] md:!pb-32 lg:pb-0 reviewSwiper wow animate__animated animate__fadeInUp">
                                    <div className="swiper-wrapper">
                                        <div className="swiper-slide">
                                            <ReviewCard
                                                reviewText={reviewText}
                                                authorName={authorName}
                                                authorTitle={authorTitle}
                                                currentIndex={currentIndexReviewCard}
                                            />
                                        </div>
                                    </div>
                                    <div className="swiper-pagination md:!hidden"></div>
                                    <div className="swiper-button-prev PREV md:!w-[60px] md:!h-[60px] xl:!left-[63%]  lg:!left-[63%]  !left-[28%] lg:!top-[63%] !top-[88%] visible rounded-md" onClick={handlePrevReviewCard}></div>
                                    <div className="swiper-button-next NEXT md:!w-[60px] md:!h-[60px] lg:!top-[63%] !top-[88%] xl:!right-[28%] lg:!right-[22%] !right-[34%] visible   rounded-md" onClick={handleNextReviewCard}></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="publication_wrapper ">
                        <div className="lg:mx-[120px] md:mx-14 mx-4">
                            <div className="md:pt-[50px] pt-8">
                                <TopHeading text={'Publications'} />
                                <div className='md:w-11/12 w-full   lg:w-[35%] mx-auto wow animate__animated animate__fadeInUp'>
                                    <GradientText props={`What the news says about us`} />
                                </div>
                                <div className="lg:mt-[72px] md:mt-12 mt-10 grid gap-4 md:gap-11 lg:grid-cols-8 md:grid-cols-4 grid-cols-2 xl:grid-cols-8 items-center wow animate__animated animate__fadeInUp">
                                    <div className='px-2'>
                                        <img className='h-20 w-full object-contain' src={coinin} alt="" />
                                    </div>
                                    <div className='px-2'>
                                        <img className='h-20 w-full object-contain' src={aplogo} alt="" />
                                    </div>
                                    <div className='px-2'>
                                        <img className='h-20 w-full object-contain' src={microsoft} alt="" />
                                    </div>
                                    <div className='px-2'>
                                        <img className='h-20 w-full object-contain' src={altcoinbuzz} alt="" />
                                    </div>
                                    <div className='px-2'>
                                        <img className='h-20 w-full object-contain' src={benzinga} alt="" />
                                    </div>
                                    <div className='px-2'>
                                        <img className='h-20 w-full object-contain' src={smart} alt="" />
                                    </div>
                                    <div className='px-2'>
                                        <img className='h-20 w-full object-contain' src={insider} alt="" />
                                    </div>
                                    <div className='px-2'>
                                        <img className='h-20 w-full object-contain' src={cryptomonde} alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                    <div className=''>
                        <div className="lg:mx-[120px] mx-2">
                            <div className="pt-[50px] wow animate__animated animate__fadeInUp">
                                <TopHeading text={'Arma News'} />
                                <div className='lg:w-[45%] md:w-11/12 w-full mx-auto'>
                                    <GradientText props={`Check out our latest blog posts and updates`} />
                                </div>
                            </div>
                            <div className="grid mt-[71px] lg:grid-cols-3 md:grid-cols-2 grid-cols-1  gap-5">

                                {[1, 2, 3].map((items, idx) => {
                                    const delay = idx * 100 + "ms";
                                    return (
                                        <div className='wow animate__animated animate__fadeInUp' key={idx} style={{ animationDelay: delay }}>
                                            <BlogCard key={blogData[idx].id} blog={blogData[idx]} delay={delay} />
                                        </div>
                                    )
                                })}
                            </div>
                            <div className='text-center mt-[65px] mx-auto w-full flex justify-center'>
                                <NavLink to={'/Blog'} className='clip game_download rounded-lg flex gap-1.5 items-center justify-center h-[61px] px-5  rounded-br-[24px]' onClick={handleButtonClick}>
                                    <span className='text-base text-white text-center pbd tracking-wide'>Show all blogs </span>
                                </NavLink>
                            </div>
                        </div>
                    </div>
                    <div className='relative z-10'>
                        <Footer />
                    </div>
                </>
            )}
        </>
    )
})

Home.propTypes = {}

export default Home